import { ReactNode, useEffect } from 'react';
import { IPerson, MobileKeyboardType } from '@types';
import {
  StyledFormContainer,
  StyledInfoCardContainer,
  StyledInfoCard,
  StyledFormField,
} from './styles';
import * as propertiesMessage from './messages';
import { emailRegExp, mobilePhoneRegExp, phoneMask } from '@utils';
import { Input } from '@components';
import { personTypes } from '@store';
import { useForm } from 'react-hook-form';

const PersonInformationsCard = ({
  onChangePhoneNumber,
  onChangeEmail,
  headerNode,
  person,
  role = personTypes.RoleCd.BORROWER,
}: {
  onChangePhoneNumber: (role: personTypes.RoleCd) => void;
  onChangeEmail: (role: personTypes.RoleCd) => void;
  headerNode?: ReactNode;
  person: IPerson;
  role?: personTypes.RoleCd;
}) => {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const suffixRole = '_' + role;

  const phoneNumber = methods.watch('phoneNumber' + suffixRole);
  const email = methods.watch('email') + suffixRole;

  useEffect(() => {
    methods.trigger('phoneNumber' + suffixRole);
  }, [phoneNumber]);

  useEffect(() => {
    methods.trigger('email' + suffixRole);
  }, [email]);

  const formatDisplayPhoneNumber = (phoneNumber = '') => {
    return phoneNumber.replaceAll(' ', '').replace(/([0-9]{2})/g, '$1 ');
  };

  return (
    <StyledInfoCardContainer>
      <StyledInfoCard headerNode={headerNode ?? null}>
        <StyledFormContainer withHeaderNode={!!headerNode}>
          <StyledFormField
            label={propertiesMessage.LASTNAME_FIELD_LABEL}
            name={'lastName' + suffixRole}
            defaultValue={person?.identity?.lastName}
            isReadOnly
          />
          <StyledFormField
            label={propertiesMessage.FIRSTNAME_FIELD_LABEL}
            name={'firstName' + suffixRole}
            defaultValue={person?.identity?.firstName}
            isReadOnly
          />
          <StyledFormField
            label={propertiesMessage.MOBILE_FIELD_LABEL}
            name={'phoneNumber' + suffixRole}
            defaultValue={formatDisplayPhoneNumber(
              person?.contactInformation?.mobilePhoneNumber
            )}
            validate={(newValue: string) =>
              mobilePhoneRegExp.test((newValue ?? '').replaceAll(' ', ''))
            }
            isReadOnly={false}
            render={(
              { onChange, value, ref, onBlur, name },
              { invalid, isTouched }
            ) => (
              <Input
                inputMode={MobileKeyboardType.TEL}
                name={name}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={(newValue) => {
                  onChange(formatDisplayPhoneNumber(newValue + ''));
                  onChangePhoneNumber(role);
                }}
                mask={phoneMask}
                error={
                  isTouched && invalid
                    ? propertiesMessage.ERROR_MOBILE_PHONE_NUMBER
                    : ''
                }
              />
            )}
          />
          <StyledFormField
            label={propertiesMessage.EMAIL_FIELD_LABEL}
            name={'email' + suffixRole}
            validate={(newValue: string) => emailRegExp.test(newValue)}
            isReadOnly={false}
            defaultValue={person?.contactInformation?.email}
            render={(
              { onChange, value, ref, onBlur, name },
              { invalid, isTouched }
            ) => (
              <Input
                inputMode={MobileKeyboardType.EMAIL}
                name={name}
                value={value}
                isTouched={isTouched}
                isValid={!invalid}
                onBlur={onBlur}
                inputRef={ref}
                onChange={(newValue) => {
                  onChange(newValue.toString());
                  onChangeEmail(role);
                }}
                maxLength={50}
                error={
                  isTouched && invalid ? propertiesMessage.ERROR_EMAIL : ''
                }
              />
            )}
          />
        </StyledFormContainer>
      </StyledInfoCard>
    </StyledInfoCardContainer>
  );
};

export default PersonInformationsCard;
