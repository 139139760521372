import { Title, ButtonBlockFooter } from '@components';
import { useEffect, useRef } from 'react';
import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import {
  StyledButtonCheck,
  StyledCard,
  StyledTitleWrapper,
  StyledWrapper,
  StyleTitleIconWrapper,
} from './styles';
import * as messages from './messages';

export interface IFormProps {
  onSubmit: (methods: UseFormReturn) => void;
  children?: React.ReactNode;
  title?: string;
  titleIcon?: React.ReactNode;
  expand?: boolean;
  isVisible?: boolean;
  hasValidationButton?: boolean;
  isSubmitLoading?: boolean;
  onClickModify?: () => void;
  scrollToTop?: boolean;
}

const Form: React.FC<IFormProps> = ({
  onSubmit,
  children,
  title,
  titleIcon,
  expand = false,
  isVisible = true,
  hasValidationButton = true,
  isSubmitLoading = false,
  onClickModify,
  scrollToTop = true,
}: IFormProps) => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { isValid } = methods.formState;
  const formHtmlElement = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!isVisible || !scrollToTop) {
      return;
    }
    formHtmlElement?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [isVisible]);

  const onClickNext = () => {
    if (isValid) {
      onSubmit(methods);
    }
  };

  return (
    <>
      {isVisible && (
        <StyledCard>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(() => onSubmit(methods))}
              ref={formHtmlElement}
            >
              <StyledWrapper>
                <StyledTitleWrapper>
                  <StyleTitleIconWrapper>
                    {!!titleIcon && titleIcon}
                    {title && <Title level={2}>{title}</Title>}
                  </StyleTitleIconWrapper>
                  {!expand && onClickModify && (
                    <>
                      <StyledButtonCheck
                        name={'Modify-Form-' + (title ?? '')}
                        onClick={onClickModify}
                      >
                        {messages.BTN_MODIFY}
                      </StyledButtonCheck>
                    </>
                  )}
                </StyledTitleWrapper>
                {expand && (
                  <>
                    {children}
                    {hasValidationButton && (
                      <ButtonBlockFooter
                        onClickNext={onClickNext}
                        isLoading={isSubmitLoading}
                      />
                    )}
                  </>
                )}
              </StyledWrapper>
            </form>
          </FormProvider>
        </StyledCard>
      )}
    </>
  );
};

export default Form;
