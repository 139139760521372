import { Icons, ModalContent } from '@components';
import * as propertiesMessages from './messages';
import { StyledText, StyledWrapperText, WrapperPrompt } from './styles';

interface IUnavailableServiceProps {
  handleUnavailableService: () => void;
}

export const UnavailableService: React.FC<IUnavailableServiceProps> = ({
  handleUnavailableService = () => undefined,
}) => {
  const body = (
    <StyledWrapperText>
      <StyledText>
        <>
          {propertiesMessages.TEXT_START_UNAVAILABLE_SERVICE}{' '}
          <strong>{propertiesMessages.TEXT_BOLD_UNAVAILABLE_SERVICE}</strong>
        </>
      </StyledText>
    </StyledWrapperText>
  );

  return (
    <WrapperPrompt>
      <ModalContent
        icon={<Icons.CloudIcon />}
        title={propertiesMessages.TITLE_UNAVAILABLE_SERVICE}
        text={body}
        tertiaryButtonLabel={propertiesMessages.BUTTON_UNAVAILABLE_SERVICE}
        onTertiaryButtonClick={handleUnavailableService}
      />
    </WrapperPrompt>
  );
};

export default UnavailableService;
