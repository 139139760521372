import { IOption, ScoreLightType, TypeDeContrat, IParametresOcr } from '@types';
import { IProduit } from 'store/souscriptionApi/souscriptionApiTypes';

export enum PaymentMode {
  DIFF = 'D', // paiement différé
  IMM = 'I', // paiement immédiat
}

export interface IProject {
  productTypeCd?: string;
  cardTypeCd?: string;
  orderNb?: string;
  purchaseAmt?: number;
  depositAmt?: number;
  purchaseCd?: string;
}

export enum TypeVente {
  FACE_A_FACE = 'FACE_A_FACE',
  VENTE_A_DISTANCE = 'VENTE_A_DISTANCE',
}

// State Interface
// TODO : faire le ménage pour supprimer ce qui n'est pas utilisé
export interface IState {
  organizationId?: string;
  territory?: string;
  chainNb?: string;
  companyCd?: string;
  project?: IProject;
  vendorId?: string;
  installmentId?: string;
  souscriptionId?: string;
  isQrActivated?: boolean;
  isDebranchementQRCode: boolean;
  switchDeviceId?: string;
  urlFinSouscription?: string;
  isPassageDeContexte?: boolean;
  initAppUrlParams?: string;
  parametres_ocr?: IParametresOcr;
  urlMentionLegales?: string;
  isInFormationMode?: boolean;
  requestId?: string;
  envName?: string;
  timeout?: number;
  launchSnippetUrl?: string;
  immediatePaymentRightBo?: boolean;
  availableCashPaiementCd?: PaymentMode[];
  defaultCashPaiementCd?: PaymentMode;
  alternativeOfferThreshold?: number;
  businessLine?: string;
  cardName?: string;
  availableToRiba?: boolean;
  isSwitchDeviceTablet?: boolean;
  switchDeviceVendorRight?: number;
  tokenJwt?: string;
  featureAccess?: { [key: string]: boolean };
  products?: IProduit[];
  listPlaces?: IOption[];
  mentionLegaleOffreCommerciale?: string;
  mentionLegaleEmprunteur?: string;
  mentionLegaleAssurance?: string;
  listCivility?: IOption[];
  listTypePieceIdentite?: IOption[];
  listSituationLogement?: IOption[];
  listStatutsLocaux?: IOption[];
  listStatutsProfessionnels?: IOption[];
  situationsFamilialeMonoEmprunteur?: IOption[];
  situationsFamilialeCoEmprunteur?: IOption[];
  professions?: IOption[];
  typeContrat?: TypeDeContrat;
  secteursActivite?: ISecteurActivite[];
  seuilMontantCreditPourAffichageInfosEmployeur?: number;
  scoreLight?: ScoreLightType;
  marqueBlanche?: boolean;
  typeVente?: TypeVente;
  isCleared?: boolean;
  isSimu?: boolean;
  idAppareil: string;
}

export interface ISecteurActivite {
  secteur: IOption;
  professions: IOption[];
  typesContrat: IOption[];
}
