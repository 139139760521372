import { Icons } from '@components';
import ModalQRCode, { EModalState } from '../ModalQRCode/ModalQRCode';
import {
  StyledButtonQRCode,
  StyledTextTooltip,
  StyledTooltipInfo,
  WrapperButtonQRCode,
  WrapperIconQRCode,
  WrapperTooltipButtonQRCode,
} from './styles';
import * as messages from './messages';

export interface IProps {
  url: string;
  isVisible?: boolean;
  isButtonQRCodeLoading?: boolean;
  isLoading?: boolean;
  onSubmitModal?: () => void;
  onClick?: () => void;
  isButtonVisible?: boolean;
  onClickPoursuivre?: () => void;
  onClickArretParcoursSwitchDevice: () => void;
  onClickCancel: () => void;
  onClickTelechargementReussi: () => void;
  setModalState: React.Dispatch<React.SetStateAction<EModalState>>;
  modalState: EModalState;
}

const QRCodeBlock: React.FC<IProps> = ({
  url,
  onClick = () => null,
  isVisible = false,
  isLoading = false,
  isButtonVisible = false,
  isButtonQRCodeLoading = false,
  onSubmitModal: onSubmit,
  onClickPoursuivre: onClickPoursuivre,
  onClickArretParcoursSwitchDevice: onClickArretParcoursSwitchDevice,
  onClickCancel: onClickCancel,
  onClickTelechargementReussi: onClickTelechargementReussi,
  setModalState,
  modalState,
}) => (
  <>
    {isButtonVisible && (
      <WrapperTooltipButtonQRCode>
        <StyledTooltipInfo
          skin="info"
          isVisible
          icon={Icons.AdviceIcon}
          hasArrowDown={false}
        >
          <StyledTextTooltip>{messages.INFO_SCAN_DOCUMENTS}</StyledTextTooltip>
        </StyledTooltipInfo>

        <WrapperButtonQRCode>
          <StyledButtonQRCode
            variant="secondary"
            onClick={onClick}
            isLoading={isButtonQRCodeLoading}
          >
            {
              <WrapperIconQRCode>
                <Icons.ScanQRCodeIcon />
              </WrapperIconQRCode>
            }
          </StyledButtonQRCode>
        </WrapperButtonQRCode>
      </WrapperTooltipButtonQRCode>
    )}
    <ModalQRCode
      url={url}
      isVisible={isVisible}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClickPoursuivre={onClickPoursuivre}
      onClickArretParcoursSwitchDevice={onClickArretParcoursSwitchDevice}
      onClickCancel={onClickCancel}
      modalState={modalState}
      setModalState={setModalState}
      onClickTelechargementReussi={onClickTelechargementReussi}
    />
  </>
);

export default QRCodeBlock;
