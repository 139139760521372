import { Button } from '@components';
import { useNavigate } from '@hooks';
import * as messages from './messages';
import {
  WrapperButtons,
  WrapperBtnBack,
  StyledChevronLeftIcon,
  WrapperBtnNext,
} from './styles';
import { VariantType } from '@components/Button/Button';

export interface IButtonsFooterProps {
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  onClickBack?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  nextStep?: string;
  backLabel?: string;
  nextLabel?: string;
  isLoading?: boolean;
  className?: string;
  isReadyToNavigate?: boolean;
  isNextStepEnabled?: boolean;
  lockNavigate?: boolean;
  type?: 'button' | 'submit' | 'reset';
  requestsToWait?: {
    isLoading: boolean;
    isUninitialized: boolean;
  }[]; // Queries ou Mutations de RTK Query
  isNextVisible?: boolean;
  isBackVisible?: boolean;
  nextVariant?: VariantType;
}

export const ButtonsFooter: React.FC<IButtonsFooterProps> = ({
  isBackDisabled = false,
  isNextDisabled = false,
  onClickBack = () => {},
  onClickNext = () => {},
  nextStep,
  backLabel,
  nextLabel,
  isLoading = false,
  className = '',
  isReadyToNavigate,
  requestsToWait,
  isNextStepEnabled = true,
  lockNavigate = false,
  type,
  isNextVisible = true,
  isBackVisible = true,
  nextVariant = 'primary',
}: IButtonsFooterProps) => {
  const { navigate, getNextStep, navigateBack } = useNavigate();

  const onBack = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClickBack) {
      onClickBack(event);
    }
    navigateBack();
  };

  const goToNextStep = () => {
    if (!isNextStepEnabled || lockNavigate) {
      return;
    }
    const toStep = nextStep ?? getNextStep();
    if (toStep) {
      navigate(toStep ?? '', { state: { isNavButton: true } });
    }
  };

  return (
    <WrapperButtons className={'ph-buttons-footer ' + className}>
      <WrapperBtnBack>
        {isBackVisible && (
          <Button variant="link" isDisabled={isBackDisabled} onClick={onBack}>
            <StyledChevronLeftIcon />
            {backLabel ?? messages.BACK_BUTTON}
          </Button>
        )}
      </WrapperBtnBack>
      <WrapperBtnNext>
        {isNextVisible && (
          <Button
            variant={nextVariant}
            onClick={onClickNext}
            isDisabled={isNextDisabled}
            isLoading={isLoading}
            actionAfterLoading={goToNextStep}
            requestsToWait={requestsToWait}
            isReadyFromParent={isReadyToNavigate}
            isActionValid={!lockNavigate}
            type={type}
          >
            {nextLabel ?? messages.NEXT_BUTTON}
          </Button>
        )}
      </WrapperBtnNext>
    </WrapperButtons>
  );
};

export default ButtonsFooter;
