import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Background,
  StyledWrapper,
  ButtonClose,
  StyledCloseIcon,
  StyledCloseMobileIcon,
} from './styles';
import { useDetectMobileDevice } from '@hooks';

export interface IModalProps {
  children?: React.ReactNode;
  handleCloseModal?: () => void;
  isVisible?: boolean;
  isClosableBtnDisabled?: boolean;
  isClosable?: boolean;
  className?: string;
  portalId?: string;
}

const Modal = ({
  children,
  handleCloseModal,
  isVisible = false,
  isClosable = false,
  isClosableBtnDisabled = false,
  className = '',
  portalId = 'modal',
}: IModalProps): React.ReactElement => {
  const { body } = document;
  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyboardEvent = (event: Event) => {
    event.preventDefault();
  };

  const [domReady, setDomReady] = useState(false);
  const { isMobileScreenSize } = useDetectMobileDevice();

  useEffect(() => {
    setDomReady(true);
  }, []);

  useEffect(() => {
    if (body) {
      body.style.overflow = 'auto';
      if (isVisible) {
        body.style.overflow = 'hidden';
        document
          .querySelector('#app')
          ?.addEventListener('keydown', handleKeyboardEvent, false);
      }
    }

    if (modalRef?.current) {
      const div = modalRef?.current;
      // set focus on second focusable element if modal is closable, otherwise on first
      const focusableElement = div.querySelectorAll(
        'button, [href], input, select, textarea'
      )[isClosable ? 1 : 0] as HTMLElement;

      if (focusableElement) {
        focusableElement.focus();
      }
    }
    return () => {
      if (body) {
        body.style.overflow = 'auto';
        document
          .querySelector('#app')
          ?.removeEventListener('keydown', handleKeyboardEvent, false);
      }
    };
  }, [body, isClosable, isVisible]);

  return domReady ? (
    createPortal(
      <div ref={modalRef} className={'ph-modal ' + className}>
        <Background
          id="modalContent"
          isVisible={isVisible}
          className={isVisible ? 'visible' : ''}
        >
          <StyledWrapper>
            {isClosable && (
              <ButtonClose
                isDisabled={isClosableBtnDisabled}
                onClick={isClosableBtnDisabled ? () => {} : handleCloseModal}
              >
                {isMobileScreenSize() ? (
                  <StyledCloseMobileIcon isDisabled={isClosableBtnDisabled} />
                ) : (
                  <StyledCloseIcon isDisabled={isClosableBtnDisabled} />
                )}
              </ButtonClose>
            )}
            {children}
          </StyledWrapper>
        </Background>
      </div>,
      document.getElementById(portalId)!
    )
  ) : (
    <div />
  );
};

export default Modal;
