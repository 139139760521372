import { useBorrowers, useTransformDocumentsUploaded } from '@hooks';
import {
  IDocumentsUploaded,
  selectConfiguration,
  selectOffer,
  useAppSelector,
} from '@store';
import { IBlock, IOcr } from '@types';
import useSwitchDevice from './useSwitchDevice';

export enum ETypeEmprunteur {
  emprunteur_principal = 'EMPRUNTEUR_PRINCIPAL',
  co_emprunteur = 'CO_EMPRUNTEUR',
  no_emprunteur_type = 'NO_EMPRUNTEUR_TYPE',
}
export enum EEvidenceBlocksTypeNames {
  BLOCK_COMMANDE = 'blocksCommande',
  BLOCK_COBORROWER = 'blocksCoborrower',
  BLOCK_BORROWER = 'blocksBorrower',
  BLOCK_BANQUE = 'blocksBanque',
}

const useBlocksState = () => {
  const { transformDocumentsUploaded } = useTransformDocumentsUploaded();

  const { resultDocumentsUploaded } = useSwitchDevice();
  const offer = useAppSelector(selectOffer);
  const { hasCoborrower } = useBorrowers();
  const ocrParameters =
    useAppSelector(selectConfiguration).parametres_ocr?.parametres ?? {};
  const blocks = JSON.parse(
    JSON.stringify(
      useAppSelector(selectConfiguration).parametres_ocr?.parametres.blocks ??
        {}
    )
  );

  const getTypeEmprunteurByBlockType = ({
    evidenceBlocksName,
  }: {
    evidenceBlocksName: string;
  }) => {
    return evidenceBlocksName === EEvidenceBlocksTypeNames.BLOCK_COBORROWER
      ? ETypeEmprunteur.co_emprunteur
      : evidenceBlocksName === EEvidenceBlocksTypeNames.BLOCK_BORROWER
      ? ETypeEmprunteur.emprunteur_principal
      : ETypeEmprunteur.no_emprunteur_type;
  };

  const isDisplayedByBorrower = ({
    ocrParameters,
    evidenceBlocksName,
  }: {
    ocrParameters: IOcr;
    evidenceBlocksName: string;
  }) =>
    !(
      (evidenceBlocksName === EEvidenceBlocksTypeNames.BLOCK_COBORROWER &&
        !hasCoborrower()) ||
      (offer.inputLoanAmount <= ocrParameters?.overdraftThresholdBDC &&
        evidenceBlocksName === EEvidenceBlocksTypeNames.BLOCK_COMMANDE)
    );

  const isDisplayedByThreshold = ({
    block,
    ocrParameters,
    evidenceBlocksName,
    evidenceType,
  }: {
    block: IBlock;
    ocrParameters: IOcr;
    evidenceBlocksName: string;
    evidenceType: string;
  }) =>
    // pas d'affichage si seuil non atteint et bloc à cacher
    !(
      block.evidenceBlocks[evidenceType].hideUnderThreshold &&
      ((offer.inputLoanAmount <= ocrParameters?.overdraftThresholdBDC &&
        evidenceBlocksName === EEvidenceBlocksTypeNames.BLOCK_COMMANDE) ||
        (offer.inputLoanAmount <= ocrParameters?.overdraftThreshold &&
          evidenceBlocksName !== EEvidenceBlocksTypeNames.BLOCK_COMMANDE))
    );

  const isBlockValidated = ({
    block,
    evidenceType,
    typeEmprunteur,
  }: {
    block: IBlock;
    evidenceType: string;
    typeEmprunteur: ETypeEmprunteur;
  }): boolean => {
    if (!resultDocumentsUploaded?.data || !block) {
      return false;
    }

    const documentsUploaded =
      transformDocumentsUploaded(resultDocumentsUploaded?.data) ?? [];

    const blockTypePiece = (
      (block.evidenceBlocks ?? [])[evidenceType]?.name ?? ''
    ).toString();
    let isDocumentUploaded = false;

    const sousTypesPiecesBlock =
      ((block?.evidenceBlocks ?? [])[evidenceType]?.evidenceTypes ?? []).map(
        (evidenceType) => evidenceType?.id
      ) ?? [];

    for (const documentUploaded in documentsUploaded) {
      if (
        Object.prototype.hasOwnProperty.call(
          documentsUploaded,
          documentUploaded
        )
      ) {
        const element = documentsUploaded[documentUploaded];
        if (!sousTypesPiecesBlock.includes(element?.sousTypePiece)) {
          continue;
        }

        if (
          element?.typePiece === blockTypePiece &&
          element?.finalisation &&
          element?.typeEmprunteur === typeEmprunteur
        ) {
          isDocumentUploaded = true;
          break;
        }
      }
    }
    return isDocumentUploaded;
  };

  const isAllBlocksValidated = () => {
    if (!blocks) {
      return true;
    }
    let isAllDocUploaded = true;
    Object.keys(blocks ?? []).forEach((evidenceBlocksName) => {
      (Object.keys(blocks[evidenceBlocksName]?.evidenceBlocks) ?? []).forEach(
        (evidenceType) => {
          if (
            !(
              isDisplayedByBorrower({
                ocrParameters: ocrParameters as IOcr,
                evidenceBlocksName,
              }) &&
              isDisplayedByThreshold({
                block: blocks[evidenceBlocksName],
                ocrParameters: ocrParameters as IOcr,
                evidenceBlocksName,
                evidenceType,
              })
            )
          ) {
            return;
          }
          if (
            !isBlockValidated({
              block: blocks[evidenceBlocksName],
              evidenceType,
              typeEmprunteur: getTypeEmprunteurByBlockType({
                evidenceBlocksName,
              }),
            })
          ) {
            isAllDocUploaded = false;
          }
        }
      );
    });
    return isAllDocUploaded;
  };

  return {
    isBlockValidated,
    isAllBlocksValidated,
    isDisplayedByThreshold,
    isDisplayedByBorrower,
    getTypeEmprunteurByBlockType,
  };
};

export default useBlocksState;
