import DropdownMenu from './DropdownMenu';
import Header from './Header';
import * as ModalContents from './ModalContents';
import OfferDescription from './OfferDescription';
import Informations from './Informations';
import InformationsId from './Informations/InformationsId';
import HousingSituation from './Informations/HousingSituation';
import FinancialSituation from './Informations/FinancialSituation';
import Footer from './Footer';
import PurchaseOrder from './OfferDescription/PurchaseOrder';
import FamilySituation from './Informations/FamilySituation';
import Insurance from './Insurance';
import Summary from './Summary';
import Signature from './Signature';
import Documents from './Documents';
import CreateSimulation from './CreateSimulation';
import ParcoursSimulation from './ParcoursSimulation';
import DetailResultSimulation from './DetailResultSimulation';
import ResultsSimulation from './ResultsSimulation';
import { StyledChevronLeftIcon } from './Common/styles';
import ValidateSubscriptionSignature from './ValidateSubscriptionSignature';
import DetailGaranties from './DetailGaranties';
import VerificationContactDetails from './VerificationContactDetails';
import Choice from './Choice';
import PopInFinElectroniqueVAD from './PopInFinElectroniqueVAD';
import FooterSimu from './FooterSimu';

export {
  DropdownMenu,
  Header,
  OfferDescription,
  Informations,
  InformationsId,
  HousingSituation,
  FinancialSituation,
  PurchaseOrder,
  FamilySituation,
  Footer,
  Insurance,
  Summary,
  Signature,
  Documents,
  StyledChevronLeftIcon,
  CreateSimulation,
  ParcoursSimulation,
  DetailResultSimulation,
  ResultsSimulation,
  ValidateSubscriptionSignature,
  DetailGaranties,
  VerificationContactDetails,
  Choice,
  PopInFinElectroniqueVAD,
  FooterSimu,
};

export const { Leaving, FormationModeScoreChoice } = ModalContents;
