import { useState, useEffect } from 'react';
import {
  ButtonsFooter,
  Centering,
  Icons,
  Modal,
  ModalContent,
} from '@components';
import { IEvidenceBlock, IOcr } from '@types';
import BlockOcr from './BlockOcr';
import {
  useBorrowers,
  usePersistingState,
  useParcoursType,
  useDetectMobileDevice,
} from '@hooks';
import { detectMobileDevice, detectTabletDevice } from '@utils';
import {
  selectConfiguration,
  personTypes,
  selectOffer,
  selectPerson,
  useAppSelector,
} from '@store';
import {
  StyledMessage,
  StyledTooltip,
  TooltipWrapper,
  StyledButtonFinish,
  StyledDescription,
  StyledTitle,
  StyledTextTooltip,
  StyledTooltipInfo,
} from './styles';
import * as messages from './messages';
import QRCodeBlock from './QRCode/QRCode';
import FinishUploadMobile from './FinishUploadMobile/FinishUploadMobile';
import useSwitchDevice from './useSwitchDevice';
import useDocumentUploaded, {
  EEvidenceBlocksTypeNames,
} from './useBlocksState';

export interface IProps {
  currentBlockName?: string;
}

const Documents: React.FC<IProps> = ({ currentBlockName }) => {
  const [ocrParameters, setOcrParameters] = useState<IOcr | null>(null);
  const [currentBlock, setCurrentBlock] = usePersistingState('currentBlock', {
    name: currentBlockName,
    index: 0,
  });
  const [mandatoryBlockChecked, setMandatoryBlockChecked] = usePersistingState(
    'mandatoryBlockChecked',
    0
  );
  const [mandatoryCountBlock, setMandatoryCountBlock] = useState(0);

  const [isAnalyseSending, setIsAnalyseSending] = useState(false);

  const [selectedBlock, setSelectedBlock] = usePersistingState<
    string | undefined
  >('block-ocr-selected', undefined);

  const { hasCoborrower } = useBorrowers();

  const person = useAppSelector(selectPerson);
  const { parametres_ocr, isQrActivated } = useAppSelector(selectConfiguration);
  const paramsOcr = parametres_ocr?.parametres;
  const { ocrKo } = person?.emprunteur ?? '';

  const isMobileDevice = detectMobileDevice() || detectTabletDevice();
  const { isMobileScreenSize } = useDetectMobileDevice();
  const { isParcoursNominal, isParcoursSwitchDevice } = useParcoursType();
  const {
    isFinishUploadMobile,
    urlQRCode,
    isModalQRCodeVisible,
    isLoadingQRCodeButton,
    onClickFinishButton,
    onClickCancel,
    onClickArretParcoursSwitchDevice,
    onClickQRCodeButton,
    onClickTelechargementReussi,
    modalState,
    setModalState,
    isTelechargementInterrompu,
  } = useSwitchDevice();

  useEffect(() => {
    if (!paramsOcr) {
      return;
    }
    // Get OCR Parameters
    setOcrParameters(paramsOcr as IOcr);
    handleCountMandatory(paramsOcr as IOcr);
  }, [paramsOcr]);

  const {
    isBlockValidated,
    isAllBlocksValidated,
    isDisplayedByThreshold,
    isDisplayedByBorrower,
    getTypeEmprunteurByBlockType,
  } = useDocumentUploaded();

  const blocks = JSON.parse(JSON.stringify(ocrParameters?.blocks ?? {}));

  const handleCountMandatory = (data: IOcr) => {
    setMandatoryCountBlock(0);
    Object.keys(data.blocks).forEach((i) => {
      if (
        !(
          offer.inputLoanAmount <= data?.overdraftThresholdBDC &&
          i === EEvidenceBlocksTypeNames.BLOCK_COMMANDE
        )
      ) {
        if (
          !(i === EEvidenceBlocksTypeNames.BLOCK_COBORROWER && !hasCoborrower())
        ) {
          const block = data.blocks[i];
          Object.keys(block.evidenceBlocks).forEach((j) => {
            const evidenceBlock = block.evidenceBlocks[j];
            if (
              !(
                evidenceBlock.hideUnderThreshold &&
                offer.inputLoanAmount <= data.overdraftThreshold
              )
            ) {
              if (data.blocks[i].evidenceBlocks[j].mandatory) {
                setMandatoryCountBlock((preValue) => preValue + 1);
              }
            }
          });
        }
      }
    });
  };

  const handleValidate = (block: IEvidenceBlock) => {
    // The next block becomes the current block
    const nextBlock = {
      name: '',
      index: currentBlock.index + 1,
    };
    setCurrentBlock(nextBlock);
    // Update button state
    if (block && block.mandatory) {
      setMandatoryBlockChecked(mandatoryBlockChecked + 1);
    }
  };

  const offer = useAppSelector(selectOffer);

  return (
    <>
      {isFinishUploadMobile ? (
        <FinishUploadMobile />
      ) : (
        <Centering>
          <StyledTitle>{messages.TITLE_PAGE_DOCUMENT}</StyledTitle>
          {!isMobileScreenSize() && (
            <>
              <StyledTooltipInfo
                skin="warning"
                isVisible
                icon={Icons.WarningIcon}
                hasArrowDown={false}
              >
                <StyledTextTooltip>{messages.SCAN}</StyledTextTooltip>
              </StyledTooltipInfo>
              <StyledTooltipInfo
                skin="advice"
                isVisible
                icon={Icons.InfoGlyphIcon}
                hasArrowDown={false}
              >
                <StyledTextTooltip>{messages.UPLOAD_LATER}</StyledTextTooltip>
              </StyledTooltipInfo>
            </>
          )}
          <QRCodeBlock
            url={urlQRCode ?? ''}
            isVisible={isModalQRCodeVisible}
            isButtonQRCodeLoading={isLoadingQRCodeButton}
            isButtonVisible={
              isParcoursNominal() &&
              !isMobileScreenSize() &&
              isQrActivated &&
              !isAllBlocksValidated()
            }
            onClick={() => onClickQRCodeButton()}
            onClickArretParcoursSwitchDevice={() =>
              onClickArretParcoursSwitchDevice()
            }
            onClickCancel={onClickCancel}
            modalState={modalState}
            setModalState={setModalState}
            onClickTelechargementReussi={onClickTelechargementReussi}
          />
          {blocks &&
            Object.keys(blocks).map((key) => {
              const block = blocks[key];
              //Si il y a un seul emprunteur OU si seuil non atteint et le block est bon de commande
              return (
                isDisplayedByBorrower({
                  ocrParameters: ocrParameters as IOcr,
                  evidenceBlocksName: key,
                }) && (
                  <div key={key}>
                    <StyledTitle level={2}>{block.title}</StyledTitle>
                    {block.description && (
                      <StyledDescription>{block.description}</StyledDescription>
                    )}
                    {block.evidenceBlocks &&
                      Object.keys(block.evidenceBlocks).map(
                        (subKey) =>
                          isDisplayedByThreshold({
                            block,
                            ocrParameters: ocrParameters as IOcr,
                            evidenceBlocksName: key,
                            evidenceType: subKey,
                          }) && (
                            <BlockOcr
                              key={block.evidenceBlocks[subKey].name}
                              ocrParameters={ocrParameters as IOcr}
                              block={block.evidenceBlocks[subKey]}
                              onFormValidation={() => console.log(22)}
                              onFileUpload={() => console.log(22)}
                              borrowerType={
                                key ===
                                EEvidenceBlocksTypeNames.BLOCK_COBORROWER
                                  ? personTypes.RoleCd.COBORROWER
                                  : personTypes.RoleCd.BORROWER
                              }
                              onValidate={handleValidate}
                              isAnalyseSending={isAnalyseSending}
                              setIsAnalyseSending={setIsAnalyseSending}
                              isDocumentUploaded={isBlockValidated({
                                block,
                                evidenceType: subKey,
                                typeEmprunteur: getTypeEmprunteurByBlockType({
                                  evidenceBlocksName: key,
                                }),
                              })}
                              onExpanding={() =>
                                setSelectedBlock(
                                  block.evidenceBlocks[subKey].name + key
                                )
                              }
                              doClose={
                                (selectedBlock?.length ?? 0) > 0 &&
                                selectedBlock !==
                                  block.evidenceBlocks[subKey].name + key
                              }
                            />
                          )
                      )}
                  </div>
                )
              );
            })}
          <Modal isVisible={isTelechargementInterrompu}>
            <ModalContent
              withScroll={false}
              icon={<Icons.WarningIcon />}
              title={messages.TITLE_MODAL_TELECHARGEMENT_INTERROMPU}
              text={messages.TEXT_MODAL_TELECHARGEMENT_INTERROMPU}
            />
          </Modal>

          {isParcoursSwitchDevice() ? (
            <StyledButtonFinish
              isDisabled={isAnalyseSending}
              onClick={() => onClickFinishButton()}
            >
              {messages.LABEL_FINISH_BUTTON}
            </StyledButtonFinish>
          ) : (
            <ButtonsFooter
              isBackDisabled={isAnalyseSending}
              // On desactive le bouton suivant lorsqu'une analyse ocr est en cours
              // Sinon, il est toujours actif dans le cas d'un PC,
              // dans le cas contraire (isMobileDevice = true), on a des conditions à respecter pour que le bouton suivant soit actif
              isNextDisabled={
                isAnalyseSending ||
                (mandatoryBlockChecked < mandatoryCountBlock &&
                  !ocrKo &&
                  isMobileDevice)
              }
            />
          )}
          <TooltipWrapper>
            <StyledTooltip
              skin="deleteCustomerInfo"
              isVisible
              icon={Icons.TrashIcon}
              hasArrowDown={false}
            >
              <StyledMessage>{messages.WARNING_LABEL}</StyledMessage>
            </StyledTooltip>
          </TooltipWrapper>
        </Centering>
      )}
    </>
  );
};

export default Documents;
