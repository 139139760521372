import { useState, useEffect } from 'react';
import {
  AnalyseDocument,
  IDocument,
  IImagesFile,
  RoleCd,
  StatusCd,
  EvidenceType,
  IFile,
} from '@types';
import { useNavigate, useRedirection } from '@hooks';
import {
  selectConfiguration,
  selectPerson,
  setOcrKo,
  useAppDispatch,
  useAppSelector,
} from '@store';
import { ModalOcrContent } from './ModalOcrContent';
import { MaxFilesLimitReached, UnreadableEvidence } from './alerts';
import NoRibSepa from './alerts/NoRibSepa';
import { OcrError } from './OcrError';
import React from 'react';

export interface IUseModalOcrOutput {
  isVisible: boolean;
  isClosable: boolean;
  isClosableBtnDisabled: boolean;
  handleClose: () => void;
  modalContent: React.JSX.Element | null;
}

interface IUseModalOcrInput {
  role?: RoleCd;
  blockName: string;
  isCheckedWithAddress?: boolean;
  onCheckWithAddressClick?: () => void;
  maxReuploadAttempt: number;
  handleValidate?: () => Promise<void>;
  handleReupload?: () => void;
  getEvidenceSubTypeCd: () => string;
  clientHasHisRib?: boolean;
  isRibCanceled?: boolean;
  maxFilesLimit?: number;
  isMaxFilesLimitReached?: boolean;
  setIsMaxFilesLimitReached: (isMaxFilesLimitReached: boolean) => void;
  isNoRibSepa?: boolean;
  setNoRibSepa: (isNoRibSepa: boolean) => void;
  onNoRibCancel: () => void;
  onNoRibConfirm?: (document: IDocument, validate: () => void) => void;
  files: IFile[];
  updatePersonWithoutValidationPopin?: (
    document: IDocument,
    validate: () => void
  ) => void;
  setProcessedImages: React.Dispatch<React.SetStateAction<IImagesFile[]>>;
  ocrIsNoSEPA: boolean;
  setOcrIsNoSEPA: (value: boolean) => void;
  validateClientHasNoRib?: () => void;
  setConfirmationWithoutSignatureElect?: (value: boolean) => void;
  ocrData: AnalyseDocument | undefined;
  borrowerType?: string;
  ribOwner?: string;
}

const useModalOcr = ({
  blockName,
  isCheckedWithAddress = false,
  maxReuploadAttempt,
  handleValidate,
  handleReupload,
  getEvidenceSubTypeCd,
  clientHasHisRib,
  isRibCanceled,
  isMaxFilesLimitReached,
  setIsMaxFilesLimitReached,
  isNoRibSepa,
  setNoRibSepa,
  onNoRibCancel,
  files,
  setProcessedImages,
  ocrIsNoSEPA,
  setOcrIsNoSEPA,
  ocrData,
  borrowerType,
  ribOwner,
}: IUseModalOcrInput): IUseModalOcrOutput => {
  const [modalContent, setModalContent] =
    useState<React.JSX.Element | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isClosableBtnDisabled, setClosableBtnDisabled] = useState(false);
  const [isClosable, setIsClosable] = useState(true);
  const [isDocumentAccepted, setIsDocumentAccepted] = useState(false);
  const [isMaxReuploadAttempt, setIsMaxReuploadAttempt] = useState(false);
  const [currentUploadAttemptNb, setCurrentUploadAttemptNb] = useState(1);
  const [requalificationRIBAonRIBS, setRequalificationRIBAonRIBS] =
    useState(false);

  const person = useAppSelector(selectPerson);

  const onValidate = async (): Promise<void> => {
    if (handleValidate) {
      await handleValidate();
    }
    // remet le compte d'upload à un :
    setIsMaxReuploadAttempt(false);
    setCurrentUploadAttemptNb(1);

    // Close modal
    setIsVisible(false);
    // Evite d'envoyer l'evenementt sendData lorsque l'utilisateur décoche "Le client détient son RIB"
    if (blockName === EvidenceType.JBAN && !clientHasHisRib) {
      return;
    }
  };

  const applyProcessAuto = () => {};

  /**
   * On affiche la modale dès qu'on a du contenu à afficher
   */
  useEffect(() => {
    setIsVisible(!!modalContent);
    // S'il n'y a pas de de contenu pour la modale (i.e. pas de formulaire) et si l'utilisateur a accepté le document,
    // alors on est dans le cas d'une fin de process sans intervention de l'utilisateur final
    // (cas du justificatif de revenu avec une erreur OCR par exemple)
    if (!modalContent && isDocumentAccepted) {
      applyProcessAuto();
    }
  }, [modalContent]);

  const onReupload = () => {
    if (requalificationRIBAonRIBS) {
      setRequalificationRIBAonRIBS(false);
    }
    // suppression de images utilisé pour la prévisualisation
    setProcessedImages([]);

    setOcrIsNoSEPA(false);
    if (isNoRibSepa) {
      setNoRibSepa(false);
      return;
    }
    // S'il s'agit de la modal "noRib": lorsque le checkbox "client détient son rib" est décoché, on call onNoRibCancel (qui va permettre de recocher la case)
    if (!clientHasHisRib) {
      onNoRibCancel();
      return;
    }
    if (handleReupload) {
      handleReupload();
    }
    // Compte le nombre de tentative et bloque à 3:
    const isMaxReupload = currentUploadAttemptNb >= maxReuploadAttempt - 1;
    setIsMaxReuploadAttempt(isMaxReupload);
    setClosableBtnDisabled(isMaxReupload);
    setCurrentUploadAttemptNb((value) => value + 1);
    // Close modal
    setIsVisible(false);
    // La modale retour OCR ne s'affiche pas
    setIsDocumentAccepted(false);
  };

  /**
   * Acceptation de la pièce :
   *  fermeture la modale, flag accepté, affichage du formulaire
   */
  const onAccept = () => {
    setIsVisible(false);
    setIsDocumentAccepted(true);
    const modal = ModalOcrContent({
      ocrData,
      onValidate,
      borrowerType,
      isMaxReuploadAttempt,
      onReupload,
      ribOwner,
    });
    setModalContent(modal);
  };

  const { redirigerAccueil } = useRedirection();
  const configuration = useAppSelector(selectConfiguration);
  const storeDispatch = useAppDispatch();
  const { navigate } = useNavigate();

  const handleUnavailableService = async () => {
    storeDispatch(setOcrKo(blockName));
    navigate('/informations', { state: { isNavButton: true } });
  };

  /**
   * Calcule le contenu de la modale dès que les données océrisées sont reçues ou dés que le checkbox "client détient son RIB" est décoché
   * Demande fonctionnelle => En cas d'erreur traitée, on affiche la modale; sinon on affiche le formulaire
   * FIX : la modale est affichée seulement si elle a un contenu
   */
  useEffect(() => {
    setModalContent(null);
    let content;
    if (ocrData?.statut == StatusCd.TIMEOUT) {
      if (blockName === EvidenceType.JDRE) {
        setModalContent(
          UnreadableEvidence({
            isMaxReuploadAttempt,
            onReupload,
            onAccepted: onValidate,
          })
        );
      } else {
        content = ModalOcrContent({
          ocrData,
          onValidate,
          borrowerType,
          isMaxReuploadAttempt,
          onReupload,
          ribOwner,
        });
        setModalContent(content);
      }
      return;
    }
    if (
      blockName === EvidenceType.JDRE &&
      [StatusCd.OK.toString(), StatusCd.INCONSISTENCY_TYPE.toString()].includes(
        ocrData?.statut ?? ''
      )
    ) {
      onValidate();
      return;
    }
    if (!clientHasHisRib && !isRibCanceled) {
      // si l'utilisateur ne détient pas de rib et que "Continuer sans rib" n'a pas déjà été fait, on affiche le contenu de la modale "sans rib"
      content = NoRibSepa({
        withButton: true,
        onClose: onReupload,
        onValidate: redirigerAccueil,
        isMaxReuploadAttempt,
      });
    } else if (!ocrData) {
      return;
    } else if (
      ocrData?.statut !== StatusCd.OK &&
      ocrData?.statut !== StatusCd.INCONSISTENCY_TYPE &&
      !isDocumentAccepted
    ) {
      if (ocrIsNoSEPA) {
        content = NoRibSepa({
          withButton: false,
          onClose: onReupload,
          onValidate: redirigerAccueil,
          isMaxReuploadAttempt,
        });
      } else {
        content = OcrError({
          ocrData,
          blockName,
          isCheckedWithAddress,
          isMaxReuploadAttempt,
          setIsModalClosable: setIsClosable,
          onReupload,
          onAccept: blockName === EvidenceType.JDRE ? onValidate : onAccept,
          onUnavailableService: handleUnavailableService,
          evidenceSubTypeCd: getEvidenceSubTypeCd(),
          files,
          redirigerAccueil,
          configuration,
        });
      }
    }
    if (!content) {
      content = ModalOcrContent({
        ocrData,
        onValidate,
        borrowerType,
        isMaxReuploadAttempt,
        onReupload,
        ribOwner,
      });
    }
    setModalContent(content);
    // S'il n'y a pas de de contenu pour la modale (i.e. pas de formulaire),
    // alors on est dans le cas d'une fin de process sans intervention de l'utilisateur final
    // (cas du justificatif de revenu par exemple)
    if (!content && ocrData?.ocr_id) {
      applyProcessAuto();
    }
  }, [ocrData, clientHasHisRib, isRibCanceled, requalificationRIBAonRIBS]);

  // Gestion de la popin du nombre de fichiers maxi que l'on peut uploader pour JDOM et JREV
  useEffect(() => {
    setModalContent(null);
    let content;
    if (isMaxFilesLimitReached) {
      content = MaxFilesLimitReached({
        onButtonClick: () => setIsMaxFilesLimitReached(false),
        maxFilesLimit: 4,
      });
      setModalContent(content);
    }
  }, [isMaxFilesLimitReached]);

  // Gestion de la popin information parcours sans RIB Mandat Sepa
  useEffect(() => {
    setModalContent(null);
    if (isNoRibSepa) {
      const content = NoRibSepa({});
      setModalContent(content);
    }
  }, [isNoRibSepa]);
  return {
    isVisible,
    isClosable,
    isClosableBtnDisabled,
    handleClose: onReupload,
    modalContent,
  };
};

export default useModalOcr;
