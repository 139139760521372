import {
  clearStore,
  selectConfiguration,
  useAppSelector,
  useLazyGetLogoutQuery,
} from '@store';
import { WindowWithEnv } from '@types';
import { getAuthenticationEnabled, getCompanyName, removeCookie } from '@utils';
import { useEffect } from 'react';
const useRedirection = (useRedirectionParams?: {
  urlFinSouscription?: string;
}): {
  redirigerAccueil: () => void;
  getUrlMentionsLegales: () => string;
  getUrlCookies: () => string;
  getUrlDonneesPersonnelles: () => string;
  logout: () => void;
  getUrlRetourAccueil: () => string;
  getAbsoluteUrlredirigerAccueilPage: (path: string) => string;
} => {
  const [triggerLogout] = useLazyGetLogoutQuery();
  const configuration = useAppSelector(selectConfiguration);
  const { vendorId } = configuration;

  const returnUrlBasename =
    useRedirectionParams?.urlFinSouscription ??
    configuration?.urlFinSouscription;

  const getUrlRedirectionPortail = (path: string): string => {
    if (returnUrlBasename) {
      return `${returnUrlBasename}/${path}`;
    }
    return '#';
  };

  const getAbsoluteUrlredirigerAccueilPage = (path: string): string => {
    if (returnUrlBasename) {
      const domain = new URL(returnUrlBasename);
      return `${domain.protocol}//${domain.hostname}:${domain.port}/${path}`;
    }
    return '#';
  };

  const redirigerAccueilPage = (path: string, isRelativePath: boolean) => {
    const url = isRelativePath
      ? getUrlRedirectionPortail(path)
      : getAbsoluteUrlredirigerAccueilPage(path);
    sessionStorage.clear();
    clearStore();
    window.location.replace(url);
  };

  const redirigerAccueil = () => {
    redirigerAccueilPage('accueil', true);
  };

  const logout = () => {
    if (getAuthenticationEnabled()) {
      triggerLogout();
    }
    if (configuration?.isPassageDeContexte) {
      redirigerAccueilPage('', false);
    } else {
      redirigerAccueilPage(
        `c/portal/logout?referer=/web/${returnUrlBasename?.split('/').pop()}/`,
        false
      );
    }
  };

  const urlByGroup = `group/${returnUrlBasename?.split('/').pop()}`;

  const envValues = ((window as unknown as WindowWithEnv)._env_ ??
    {}) as unknown as {
    returnUrlCetelem: string;
    returnUrlDomofinance: string;
    returnUrlCmag: string;
    returnUrlCmoi: string;
    returnUrlCafineo_ag: string;
    returnUrlCafineo_oi: string;
  };
  const companyKey = ('returnUrl' +
    getCompanyName(vendorId).charAt(0).toUpperCase() +
    getCompanyName(vendorId).slice(1).toLowerCase()) as
    | 'returnUrlCetelem'
    | 'returnUrlDomofinance'
    | 'returnUrlCmag'
    | 'returnUrlCmoi'
    | 'returnUrlCafineo_ag'
    | 'returnUrlCafineo_oi';
  const returnUrlBasenameMentionsLegales = envValues[companyKey];

  return {
    redirigerAccueil,
    getAbsoluteUrlredirigerAccueilPage,
    getUrlMentionsLegales: () =>
      `${returnUrlBasenameMentionsLegales}/mentions-legales`,

    getUrlCookies: () =>
      getAbsoluteUrlredirigerAccueilPage(`${urlByGroup}/cookies`),
    getUrlDonneesPersonnelles: () =>
      getAbsoluteUrlredirigerAccueilPage(`${urlByGroup}/donnees-personnelles`),
    logout,
    getUrlRetourAccueil: () =>
      getAbsoluteUrlredirigerAccueilPage(`${urlByGroup}/accueil`),
  };
};

export default useRedirection;
