/*
 * Messages
 */

export const CONTAINER_TITLE = 'Coordonnées';
export const INPUT_ADDRESS_LINE_ONE = 'Adresse postale';
export const INPUT_ADDRESS_LINE_TWO = "Complément d'adresse";
export const INPUT_ZIP_CODE = 'Code postal';
export const INPUT_CITY = 'Ville';
export const INPUT_EMAIL = 'Adresse e-mail';
export const INPUT_MOBILE_PHONE_NUMBER = 'Numéro de mobile';
export const INPUT_FIX_PHONE_NUMBER = 'Numéro de fixe';
export const PLACEHOLDER_EMAIL = 'exemple@exemple.com';
export const PLACEHOLDER_MOBILE_PHONE_NUMBER = '06 00 00 00 00';
export const PLACEHOLDER_FIX_PHONE_NUMBER = '01 00 00 00 00';
export const ERROR_ZIPCD = 'Code postal non reconnu';
export const ERROR_EMAIL = 'Saisie non conforme';
export const ERROR_PHONE_NUMBER =
  'Il faut renseigner au moins un numéro de téléphone';
export const ERROR_MOBILE_PHONE_NUMBER =
  'Numéro de mobile non conforme, doit commencer par 06 ou 07';
export const ERROR_FIX_PHONE_NUMBER =
  'Numéro de fixe non conforme, doit commencer par 01, 02, 03, 04, 05 ou 09';
export const TOOLTIP_CONTACT_TITLE = 'Informations contact';
export const TOOLTIP_PHONE_ADD =
  "L'ajout du numéro de mobile ou fixe est obligatoire.";
export const TOOLTIP_MOBILE_PHONE_NOTE =
  'À noter : Le numéro de mobile est indispensable pour la signature électronique du dossier.';
