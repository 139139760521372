export const LAYOUT_TITLE = 'Récapitulatif de la demande de crédit';
export const LAYOUT_DESC =
  "Veuillez vérifier l'ensemble des informations ci-dessous avant de procéder à la signature du contrat.";

export const IDENTITE_EMPRUNTEUR_TITLE = 'Identité de l’emprunteur';
export const IDENTITE_COEMPRUNTEUR_TITLE = 'Identité du co-emprunteur';
export const NOM_PERSONNE = 'Nom';
export const PRENOM_PERSONNE = 'Prénom';

export const OFFRE_FINANCEMENT_TITLE = 'L’offre de financement ';
export const CATEGORIE_TRAVAUX = 'Catégorie de travaux';
export const MONTANT_ACHAT = 'Montant de l’achat';
export const MONTANT_APPORT = 'Montant de l’apport';
export const MONTANT_CREDIT = 'Montant total du crédit';
export const TAEG = 'TAEG (hors coût d’assurance facultative)';
export const MENSUALITE = 'Mensualité (hors coût d’assurance facultative)';
export const NOMBRE_MENSUALITES = 'Nombre de mensualités';
export const NOMBRE_MENSUALITES_DESCRIPTION = ' mensualités';
export const INTERETS = 'Intérêts';
export const REPORT = 'Report';
export const REPORT_UNITES = ' jours';

export const ASSURANCE_EMPRUNTEUR_TITLE = 'Assurance choisie - emprunteur';
export const ASSURANCE_COEMPRUNTEUR_TITLE = 'Assurance choisie - co-emprunteur';
export const COUT_MENSUEL = 'Coût mensuel de l’assurance facultative';
export const COUT_TOTAL = 'Coût total de l’assurance facultative';
export const TAEA = 'TAEA Taux annuel effectif de l’assurance';
export const MENSUALITE_TOTALE_EMPRUNTEUR =
  'Mensualité totale (crédit et assurance emprunteur)';
export const MENSUALITE_TOTALE_COEMPRUNTEUR =
  'Mensualité totale (crédit et assurance co-emprunteur)';

export const SYNTHESE_ASSURANCES_TITLE = 'Synthèse des assurances facultatives';
export const MENSUALITE_SYNTHESE =
  'Mensualité du crédit avec les deux assurances facultatives';
export const COUT_MENSUEL_ASSURANCES_SYNTHESE =
  'Coût mensuel des deux assurances facultatives';
export const COUT_TOTAL_ASSURANCES_SYNTHESE =
  'Coût total des deux assurances facultatives';
export const TAEA_SYNTHESE = 'TAEA global';

export const NO_INSURANCE = 'Aucune';
