export const TITLE_GREEN = 'Avis favorable';
export const TITLE_ORANGE = 'Avis en attente';
export const TITLE_RED = 'Notre décision';
export const TEXT_GREEN =
  'La demande de votre client est pré-acceptée sous réserve du contrôle des pièces justificatives.';
export const TEXT_ORANGE =
  'La demande de votre client nécessite une étude approfondie par nos experts.';
export const TEXT_RED =
  "En l'état des informations transmises à ce stade, nous ne pouvons répondre favorablement à la demande de votre client.";
export const TEXT_PAPER =
  'Merci de choisir un mode de signature avec votre client pour finaliser la demande.';
export const BUTTON_PAPER = 'Signature papier';
export const BUTTON_ELECTRONIC = 'Signature électronique';
export const BUTTON_LINK_HOME = "Retourner à l'accueil";
export const TITLE_TOOLTIP = 'Informations client';
export const TEXT_TOOLTIP =
  'Pensez à supprimer les informations et photographies des pièces justificatives des emprunteurs sur cet appareil chaque jour.';
export const BUTTON_PAPER_VAD = 'Signature papier à distance';
export const BUTTON_ELECTRONIC_VAD = 'Signature électronique à distance';
