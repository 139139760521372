import { useEffect, useRef, useState } from 'react';
import {
  StyledTitleContainer,
  StyledTitle,
  StyledContainer,
  ElectronicSignatureContainer,
  ElectronicSignatureTitle,
  StyledLink,
  ElectronicSignatureText,
  ValidateSubscriptionWrapper,
  StyledCardWrapper,
  StyledWrapperHeaderNode,
  StyledFooter,
} from './styles';
import * as propertiesMessage from './messages';
import { FormProvider, useForm } from 'react-hook-form';
import {
  selectConfiguration,
  selectPerson,
  updateConfiguration,
  useAppDispatch,
  useAppSelector,
  useSendContratPapierMutation,
} from '@store';
import { RoleCd, TypeDeContrat, TypeVente } from '@types';
import { ContractPaperIcon } from '@icons';
import {
  useBorrowers,
  useNavigate,
  usePersistingState,
  useRedirection,
} from '@hooks';
import PersonInformationsCard from './PersonInformationsCard';
import Overlay from './Overlay';
import useSendElectronicContract, { fieldNames } from './useSendContract';
import PopInFinElectroniqueVAD from '../PopInFinElectroniqueVAD';
import { ButtonsFooter } from '@components';
import { openPdfFile } from '@utils';

export interface IProps {
  onSign?: (isElectronical: boolean) => undefined;
  onChangeUserData?: () => void;
  handleFailureValidate?: (message?: string) => void;
  manualSignature?: () => void;
}

const ModalContent: React.FC<IProps> = () => {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [sendContrat, responseContrat] = useSendContratPapierMutation();
  const { souscriptionId } = useAppSelector(selectConfiguration);
  const dispatch = useAppDispatch();
  const { navigate, getNextStep } = useNavigate();
  const [signType, setSignType] = useState<TypeDeContrat>(TypeDeContrat.PAPIER);
  const { typeVente } = useAppSelector(selectConfiguration);
  const [isVAD] = useState(typeVente === TypeVente.VENTE_A_DISTANCE);
  const { redirigerAccueil } = useRedirection();

  const [isLoading, setIsLoading] = useState(false);
  const [isSigning, setIsSigning] = usePersistingState(
    'is-signing-signature-electronique',
    false
  );
  const redirectionLink = useRef<HTMLAnchorElement>(null);
  const [urlNetDemat, setUrlNetDemat] = usePersistingState(
    'url-net-demat-signature-electronique',
    ''
  );
  const [electronicSignEnded, setElectronicSignEnded] = usePersistingState(
    'signature-electronique-terminee',
    false
  );

  const person = useAppSelector(selectPerson);
  const { hasCoborrower } = useBorrowers();

  const { updateInformationsIfNeededAndThenSign, isModifyingField } =
    useSendElectronicContract((url) => {
      setUrlNetDemat(url);
      setElectronicSignEnded(true);
      setIsLoading(false);
    }, methods);

  useEffect(() => {
    if (!responseContrat?.data?.contenu) {
      return;
    }
    openPdfFile(responseContrat.data.contenu);
    setIsLoading(false);
    navigate(getNextStep() ?? '', { state: { isNavButton: true } });
  }, [responseContrat?.data?.contenu]);

  // on scrolle en bas de la page lorsque le texte s'affiche post signature (suite dev live)
  // dès que l'url de signature électronique est connue, alors ouvre le lien
  useEffect(() => {
    if (!urlNetDemat || isVAD) {
      return;
    }
    openElectronicSignatureUrl();
    window.scrollTo(0, document.body.scrollHeight);
  }, [urlNetDemat]);

  const onManualSigning = () => {
    setIsLoading(true);
    setSignType(TypeDeContrat.PAPIER);
    dispatch(updateConfiguration({ typeContrat: TypeDeContrat.PAPIER }));
    sendContrat({ numero_souscription: souscriptionId ?? '' });
  };

  const onElectronicSigning = () => {
    setSignType(TypeDeContrat.ELECTRONIQUE);
    setIsSigning(true);
    if (urlNetDemat && !isVAD) {
      openElectronicSignatureUrl();
    } else {
      onFirstElectronicSign();
      updateInformationsIfNeededAndThenSign();
    }
  };

  const onFirstElectronicSign = () => {
    setSignType(TypeDeContrat.ELECTRONIQUE);
    setIsSigning(true);
    setIsLoading(true);
  };

  const openElectronicSignatureUrl = () => {
    if (redirectionLink?.current) {
      redirectionLink.current.click();
    }
  };

  const INFO_CARD_HEADER_NODE = (
    <StyledWrapperHeaderNode>
      {propertiesMessage.INFOCARD_HEADER_FIRST_LINE}
      <br />
      <strong>{propertiesMessage.INFOCARD_HEADER_SECOND_LINE_BOLD}</strong>
      {propertiesMessage.INFOCARD_HEADER_SECOND_LINE_END}
    </StyledWrapperHeaderNode>
  );

  const { isValid, isSubmitting } = methods.formState;

  useEffect(() => {
    // force validation on first render
    methods.trigger();
  }, []);

  const onChangePhoneNumber = (role: RoleCd) => {
    isModifyingField({ role, fieldName: fieldNames.mobile });
  };

  const onChangeEmail = (role: RoleCd) => {
    isModifyingField({ role, fieldName: fieldNames.email });
  };

  const canSign = () =>
    !(signType !== TypeDeContrat.ELECTRONIQUE && isLoading) &&
    !isSigning &&
    isValid &&
    !isSubmitting;

  const isFormSignatureVisible = (): boolean => {
    return !(isVAD && electronicSignEnded) || !urlNetDemat;
  };

  return (
    <>
      {/*si c'est pas VAD ou VAD et que la signature electronique est en erreur on affiche le formulaire, sinon si c'est VAD et que c'est signe on affiche la popIn */}
      {!isFormSignatureVisible() ? (
        <PopInFinElectroniqueVAD />
      ) : (
        <>
          <ValidateSubscriptionWrapper>
            <StyledCardWrapper>
              <FormProvider {...methods}>
                <form>
                  <Overlay isEnabled={isSigning}>
                    <StyledContainer>
                      <StyledTitleContainer>
                        <ContractPaperIcon />
                        <StyledTitle>{propertiesMessage.TITLE}</StyledTitle>
                      </StyledTitleContainer>
                    </StyledContainer>
                    <PersonInformationsCard
                      onChangePhoneNumber={onChangePhoneNumber}
                      onChangeEmail={onChangeEmail}
                      person={person?.emprunteur}
                      headerNode={INFO_CARD_HEADER_NODE}
                    />
                    {hasCoborrower() && (
                      <PersonInformationsCard
                        onChangePhoneNumber={onChangePhoneNumber}
                        onChangeEmail={onChangeEmail}
                        person={person?.coemprunteur}
                        role={RoleCd.COBORROWER}
                      />
                    )}
                  </Overlay>
                  {electronicSignEnded && (
                    <ElectronicSignatureContainer
                      isExpanded={!!electronicSignEnded}
                    >
                      <ElectronicSignatureTitle>
                        {isVAD
                          ? propertiesMessage.ELECTRONIC_SIGNATURE_TITLE_VAD
                          : propertiesMessage.ELECTRONIC_SIGNATURE_TITLE}
                      </ElectronicSignatureTitle>
                      <ElectronicSignatureText>
                        {isVAD
                          ? propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_1_VAD
                          : propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_1}
                        <StyledLink
                          onClick={onElectronicSigning}
                          isDisabled={isLoading}
                        >
                          {propertiesMessage.ELECTRONIC_SIGNATURE_BUTTON_RETRY}
                        </StyledLink>
                        {propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_2}
                        <StyledLink
                          onClick={onManualSigning}
                          isDisabled={isLoading}
                        >
                          {isVAD
                            ? propertiesMessage.ELECTRONIC_SIGNATURE_BUTTON_BACK_TO_MANUAL_VAD
                            : propertiesMessage.ELECTRONIC_SIGNATURE_BUTTON_BACK_TO_MANUAL}
                        </StyledLink>
                      </ElectronicSignatureText>
                      <ElectronicSignatureText>
                        {propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_3}
                        <br />
                        {propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_4}
                        <StyledLink
                          onClick={redirigerAccueil}
                          isDisabled={isLoading}
                        >
                          {propertiesMessage.ELECTRONIC_SIGNATURE_TEXT_5}
                        </StyledLink>
                      </ElectronicSignatureText>
                    </ElectronicSignatureContainer>
                  )}
                </form>
                <a
                  ref={redirectionLink}
                  hidden
                  href={urlNetDemat}
                  target="_blank"
                  rel="opener"
                />
              </FormProvider>
            </StyledCardWrapper>
          </ValidateSubscriptionWrapper>
          {!electronicSignEnded && (
            <StyledFooter>
              <ButtonsFooter
                isNextStepEnabled={false}
                nextLabel={propertiesMessage.ELECTRONIC_SIGN}
                onClickNext={() => {
                  if (!canSign) {
                    return;
                  }
                  onFirstElectronicSign();
                  updateInformationsIfNeededAndThenSign();
                }}
                isLoading={isLoading}
              />
            </StyledFooter>
          )}
        </>
      )}
    </>
  );
};

export default ModalContent;
