import {
  selectConfiguration,
  selectOffer,
  useAppDispatch,
  useAppSelector,
  useLazyGetCitiesByZipCdQuery,
  useLazyGetCitiesQuery,
  useLazyGetCountriesQuery,
  useLazyGetDepartmentsQuery,
  clearPersonCoEmprunteur,
  personTypes,
  useLazyGetDepartmentsByCityQuery,
  useLazyGetValidationSirenNumberQuery,
} from '@store';
import { useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { useBorrowers, usePersistingForm, usePersistingState } from '@hooks';
import { IOption, ProprietaireCompte } from '@types';
import {
  IUseInformationsForm,
  IDisplayContract,
  IUseInformationsFormParams,
} from './types';
import dayjs from 'dayjs';
import {
  AUTRES,
  LOCATAIRE,
  LOGE_PAR_FAMILLE,
  PROPRIETAIRE_AVEC_PRET,
  validateEvidenceNumber,
  zipCodeRegexp,
} from '@utils';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_WO_DAYS = 'MM/YYYY';

const useInformationsForm = ({
  role,
  setAddressCity,
  ocrBirthCity,
  shouldCheckCountry = false,
}: IUseInformationsFormParams): IUseInformationsForm => {
  const suffixRole = '_' + role;

  const configuration = useAppSelector(selectConfiguration);
  const offer = useAppSelector(selectOffer);
  const { hasCoborrower } = useBorrowers();
  const {
    situationsFamilialeMonoEmprunteur,
    situationsFamilialeCoEmprunteur,
    listCivility,
    secteursActivite,
    listStatutsLocaux,
    listStatutsProfessionnels,
    seuilMontantCreditPourAffichageInfosEmployeur,
  } = {
    ...configuration,
  };
  const dispatch = useAppDispatch();

  const [triggerGetCountries, resultGetCountries] = useLazyGetCountriesQuery();
  const [triggerGetDepartments, resultGetDepartments] =
    useLazyGetDepartmentsQuery();
  const [triggerGetDepartmentsByCity, resultGetDepartmentsByCity] =
    useLazyGetDepartmentsByCityQuery();
  const [triggerGetCities, resultGetCities] = useLazyGetCitiesQuery();
  const [triggerGetCitiesByZipCd, resultGetCitiesByZipCd] =
    useLazyGetCitiesByZipCdQuery();
  const [triggerGetCitiesByEmployerZipCode, resultGetCitiesByEmployerZipCode] =
    useLazyGetCitiesByZipCdQuery();
  const [triggerGetValidationSirenNumber, resultGetValidationSirenNumber] =
    useLazyGetValidationSirenNumberQuery();

  const { inputLoanAmount } = { ...offer };

  const FRANCE_CODE = 'FR';

  const [listCountries, setListCountries] = usePersistingState<
    IOption[] | undefined
  >('list-countries', undefined);

  const [listDepartments, setListDepartments] = usePersistingState<
    IOption[] | undefined
  >('list-departments', undefined);

  const [listDepartmentsByCity, setListDepartmentsByCity] = usePersistingState<
    IOption[] | undefined
  >('list-departments-by-city', undefined);

  const [listCities, setListCities] = usePersistingState<IOption[] | undefined>(
    'list-cities' + role,
    undefined
  );

  const [listCitiesZipCd, setListCitiesZipCd] = usePersistingState<
    IOption[] | undefined
  >('list-cities-zip-cd', undefined);

  const [listSecteursActivite, setListSecteursActivite] = usePersistingState<
    IOption[] | undefined
  >('list-secteurs-activite', undefined);

  const [listProfessions, setListProfessions] = usePersistingState<
    IOption[] | undefined
  >('list-professions' + role, undefined);

  const [listTypesContrat, setListTypesContrat] = usePersistingState<
    IOption[] | undefined
  >('list-types-contrat' + role, undefined);

  const [listSituationsFamiliale, setListSituationsFamiliale] =
    usePersistingState<IOption[] | undefined>(
      'list-situation-familiale',
      undefined
    );

  const [isFranceSelected, setIsFranceSelected] = usePersistingState<
    boolean | undefined
  >('is-france-selected' + role, undefined);

  const [isCitiesReady, setIsCitiesReady] = usePersistingState<boolean>(
    'is-cities-ready' + role,
    false
  );

  const [isCitiesByZipCdReady, setIsCitiesByZipCdReady] =
    usePersistingState<boolean>('is-cities-zip-cd-ready', false);

  const [isNameEmployerVisible, setIsNameEmployerVisible] = usePersistingState<
    boolean | null
  >('is-name-employer-visible' + role, null);

  const [isZipCdEmployerVisible, setIsZipCdEmployerVisible] =
    usePersistingState<boolean | null>(
      'is-zip-cd-employer-visible' + role,
      null
    );

  const [isActivityTypeVisible, setIsActivityTypeVisible] = usePersistingState<
    boolean | null
  >('is-activity-type-visible' + role, null);

  const [isArtisanCommercant, setIsArtisanCommercant] = usePersistingState<
    boolean | null
  >('is-artisan-commercant' + role, null);

  const [isContractTypeVisible, setIsContractTypeVisible] =
    usePersistingState<boolean>('is-contract-type-visible' + role, false);

  const [isCityEnabled, setIsCityEnabled] = usePersistingState<boolean>(
    'is-city-enabled' + role,
    true
  );

  const [errorSirenNumber, setErrorSirenNumber] = usePersistingState<
    string | null
  >('error-siren-number' + role, null);

  const refIsFranceSelected = useRef<boolean | undefined>();

  const shouldValidate = {
    shouldValidate: true,
  };

  const shouldTouchAndValidate = {
    shouldTouch: true,
    shouldValidate: true,
  };

  const { control, watch, setValue, getValues, unregister, trigger } =
    useFormContext();

  usePersistingForm('form-informations', watch, setValue);

  const inputBirthDepartment = getValues('inputBirthDepartment' + suffixRole);
  const inputCity = getValues('inputCity' + suffixRole);
  const inputZipCd = getValues('inputZipCd' + suffixRole);
  const inputEmployerZipCode = getValues('inputEmployerZipCode' + suffixRole);
  const inputEmployerActivitySector = getValues(
    'inputEmployerActivitySector' + suffixRole
  );
  const inputProfessionOrSituation = getValues(
    'inputProfessionOrSituation' + suffixRole
  );
  const inputEmployerDate = getValues('inputEmployerDate' + suffixRole);
  const inputContractType = getValues('inputContractType' + suffixRole);
  const inputMaritalStatus = getValues('inputMaritalStatus' + suffixRole);
  const inputBirthCountry = getValues('inputBirthCountry' + suffixRole);
  const inputBirthCityInsee = getValues('inputBirthCityInsee' + suffixRole);
  const inputBirthCityLabel = getValues('inputBirthCityLabel' + suffixRole);

  useEffect(() => {
    if (shouldCheckCountry) {
      triggerGetCountries();
      triggerGetDepartments();
    }
    setListSecteursActivite(buildListSecteursActiviteToDisplay());
  }, [, secteursActivite]);

  useEffect(() => {
    if (ocrBirthCity) {
      triggerGetDepartmentsByCity(ocrBirthCity);
    }
  }, [ocrBirthCity]);

  useEffect(() => {
    setListSituationsFamiliale(
      hasCoborrower()
        ? situationsFamilialeCoEmprunteur
        : situationsFamilialeMonoEmprunteur
    );

    !hasCoborrower() &&
      setValue(
        'inputAccountOwner',
        ProprietaireCompte.EMPRUNTEUR_PRINCIPAL,
        shouldValidate
      );

    clearPersonInformations();
  }, [offer.inputBorrowers]);

  useEffect(() => {
    if (!listSituationsFamiliale || !inputMaritalStatus) {
      return;
    }
    if (
      !listSituationsFamiliale?.find(
        (element) => element.id == inputMaritalStatus
      )
    ) {
      setValue('inputMaritalStatus_TIT', '', shouldValidate);
    }
  }, [listSituationsFamiliale]);

  useEffect(() => {
    if (!inputBirthCountry || !shouldCheckCountry) {
      return;
    }

    setIsFranceSelected(inputBirthCountry === FRANCE_CODE);
  }, [inputBirthCountry]);

  useEffect(() => {
    // Condition de retour en cas de refresh
    if (
      isFranceSelected === undefined ||
      refIsFranceSelected.current === undefined
    ) {
      refIsFranceSelected.current = isFranceSelected;
      return;
    }

    setListCities([]);
    setValue('inputBirthCityLabel' + suffixRole, '', shouldValidate);
    setValue('inputBirthCityInsee' + suffixRole, '', shouldValidate);
    setValue('inputBirthDepartment' + suffixRole, '', shouldValidate);
  }, [isFranceSelected]);

  useEffect(() => {
    if (listDepartmentsByCity && listDepartmentsByCity.length > 0) {
      setValue('inputBirthCountry' + suffixRole, 'FR', shouldTouchAndValidate);
      if (listDepartmentsByCity.length === 1) {
        setValue(
          'inputBirthDepartment' + suffixRole,
          listDepartmentsByCity[0].id,
          shouldTouchAndValidate
        );
      }
    }
  }, [listDepartmentsByCity]);

  useEffect(() => {
    if (!inputBirthDepartment) {
      return;
    }

    if (inputBirthDepartment === resultGetCities?.originalArgs) {
      setNewCitiesList();
    } else {
      triggerGetCities(inputBirthDepartment);
      setIsCitiesReady(false);
      if (resultGetCities?.originalArgs) {
        setValue('inputBirthCityLabel' + suffixRole, '', shouldValidate);
        setValue('inputBirthCityInsee' + suffixRole, '', shouldValidate);
      }
    }
  }, [inputBirthDepartment]);

  useEffect(() => {
    if (!listCities || listCities.length === 0) {
      return;
    }

    const foundOcrCity = listCities?.find(
      (city) => city?.label?.toLowerCase() === ocrBirthCity?.toLowerCase()
    );

    if (foundOcrCity) {
      setValue(
        'inputBirthCityInsee' + suffixRole,
        foundOcrCity.id,
        shouldValidate
      );
      setValue(
        'inputBirthCityLabel' + suffixRole,
        foundOcrCity.label,
        shouldValidate
      );
    }
  }, [listCities]);

  useEffect(() => {
    if (
      inputBirthCityInsee === undefined ||
      listCities === undefined ||
      listCities.length === 0
    ) {
      return;
    }
    const foundCity = listCities?.find(
      (city) => city.id === inputBirthCityInsee
    );
    setValue(
      'inputBirthCityLabel' + suffixRole,
      foundCity?.label ?? '',
      shouldValidate
    );
  }, [inputBirthCityInsee]);

  useEffect(() => {
    trigger('inputBirthCityLabel' + suffixRole);
  }, [inputBirthCityLabel]);

  useEffect(() => {
    if (!inputCity || !listCitiesZipCd) {
      return;
    }
    const foundCity = listCitiesZipCd?.find((city) => city.id === inputCity);
    if (setAddressCity) {
      setAddressCity(foundCity?.label ?? '');
    }
  }, [inputCity]);

  useEffect(() => {
    if (!inputZipCd) {
      return;
    }
    setIsCitiesByZipCdReady(false);
    const isValid = zipCodeRegexp.test(inputZipCd);
    if (isValid) {
      if (inputZipCd === resultGetCitiesByZipCd?.originalArgs) {
        setIsCitiesByZipCdReady(!resultGetCitiesByZipCd?.isError);
      } else {
        triggerGetCitiesByZipCd(inputZipCd);
      }
    } else {
      setValue('inputCity' + suffixRole, '', shouldValidate);
    }
  }, [inputZipCd]);

  useEffect(() => {
    if (!inputEmployerZipCode) {
      return;
    }
    const isValid = zipCodeRegexp.test(inputEmployerZipCode);
    if (
      isValid &&
      inputEmployerZipCode !== resultGetCitiesByEmployerZipCode?.originalArgs
    ) {
      triggerGetCitiesByEmployerZipCode(inputEmployerZipCode);
    }
  }, [inputEmployerZipCode]);

  useEffect(() => {
    // Condition de sortie pour gérer le retour arrière
    if (
      listCitiesZipCd === undefined ||
      isCitiesByZipCdReady === undefined ||
      inputCity === undefined
    ) {
      return;
    }

    const foundCity = listCitiesZipCd?.find((city) => city.id === inputCity);

    if (foundCity) {
      return;
    } else {
      // GESTION CODE POSTAL ET NOM DE VILLE OCERISEE
      const ocrFoundCity = listCitiesZipCd?.find(
        (city) => city.label === inputCity
      );
      if (ocrFoundCity) {
        setValue(
          'inputCity' + suffixRole,
          ocrFoundCity.id,
          shouldTouchAndValidate
        );
        if (setAddressCity) {
          setAddressCity(ocrFoundCity.label);
        }
        setIsCityEnabled(false);
        return;
      }
      setIsCityEnabled(true);
    }

    if (listCitiesZipCd?.length === 1 && isCitiesByZipCdReady) {
      setValue(
        'inputCity' + suffixRole,
        listCitiesZipCd[0].id,
        shouldTouchAndValidate
      );
    } else {
      setValue('inputCity' + suffixRole, '', shouldValidate);
    }
  }, [listCitiesZipCd, isCitiesByZipCdReady]);

  useEffect(() => {
    // Conditions pour gérer les retours arrières, si pas de valeur ou liste équivalente, on ne fait pas le useEffect
    if (!inputEmployerActivitySector) {
      return;
    }
    const newListProfessions = buildListProfessionsToDisplay(
      inputEmployerActivitySector
    );

    if (JSON.stringify(newListProfessions) == JSON.stringify(listProfessions)) {
      return;
    }

    // Construction des listes qui dépendent du secteur d'activité choisi
    setListProfessions(newListProfessions);
    setListTypesContrat(
      buildListTypesContratToDisplay(inputEmployerActivitySector)
    );

    // A chaque changement de secteur d'activité, on réinitialise les champs qui en dépendent
    if (
      !!inputProfessionOrSituation &&
      !newListProfessions?.find(
        (profession) => profession.id === inputProfessionOrSituation
      )
    ) {
      setValue('inputProfessionOrSituation' + suffixRole, '', shouldValidate);
    }
  }, [inputEmployerActivitySector]);

  useEffect(() => {
    // Conditions pour gérer les retours arrières, si pas de valeur ou liste équivalente, on ne fait pas le useEffect
    if (!inputEmployerActivitySector) {
      return;
    }
    const isArtisanCommercant = inputEmployerActivitySector === 'ACO';
    setIsActivityTypeVisible(
      isArtisanCommercant || inputEmployerActivitySector === 'PLI'
    );
    setIsArtisanCommercant(isArtisanCommercant);
    setIsNameEmployerVisible(
      displayEmployerInformations() &&
        (inputEmployerActivitySector === 'SPR' ||
          inputEmployerActivitySector === 'SPU' ||
          (inputEmployerActivitySector === 'SAG' &&
            inputProfessionOrSituation === '2'))
    );
    setIsZipCdEmployerVisible(
      displayEmployerInformations() &&
        (inputEmployerActivitySector === 'SPR' ||
          inputEmployerActivitySector === 'SPU' ||
          isArtisanCommercant ||
          inputEmployerActivitySector === 'PLI' ||
          (inputEmployerActivitySector === 'SAG' &&
            inputProfessionOrSituation === '2'))
    );
  }, [inputEmployerActivitySector, inputProfessionOrSituation]);

  useEffect(() => {
    if (isNameEmployerVisible === null) {
      return;
    }
    if (isNameEmployerVisible) {
      trigger('inputEmployerName' + suffixRole);
    } else {
      setValue('inputEmployerName' + suffixRole, '', shouldValidate);
    }
  }, [isNameEmployerVisible]);

  useEffect(() => {
    if (isZipCdEmployerVisible === null) {
      return;
    }
    if (isZipCdEmployerVisible) {
      trigger('inputEmployerZipCode' + suffixRole);
    } else {
      setValue('inputEmployerZipCode' + suffixRole, '', shouldValidate);
    }
  }, [isZipCdEmployerVisible]);

  useEffect(() => {
    if (isArtisanCommercant === null) {
      return;
    }
    if (isArtisanCommercant) {
      trigger('inputSIREN' + suffixRole);
      trigger('inputEmployeesNumber' + suffixRole);
    } else {
      setValue('inputSIREN' + suffixRole, '', shouldValidate);
      setValue('inputEmployeesNumber' + suffixRole, '', shouldValidate);
      setValue('inputWorkplaceStatusCd' + suffixRole, '', shouldValidate);
      setValue('inputProfessionnalStatusCd' + suffixRole, '', shouldValidate);
    }
  }, [isArtisanCommercant]);

  useEffect(() => {
    if (isActivityTypeVisible === null) {
      return;
    }
    if (isActivityTypeVisible) {
      trigger('inputActivityType' + suffixRole);
    } else {
      setValue('inputActivityType' + suffixRole, '', shouldValidate);
    }
  }, [isActivityTypeVisible]);

  // Champ Type de contrat
  useEffect(() => {
    if (
      !!inputContractType &&
      !!listTypesContrat &&
      !listTypesContrat?.find((type) => type.id === inputContractType)
    ) {
      setValue('inputContractType' + suffixRole, '', shouldValidate);
    } else {
      setValue(
        'inputContractType' + suffixRole,
        getValues('inputContractType' + suffixRole),
        shouldValidate
      );
    }

    // On vérifie si on doit afficher la liste des types de contrat
    const contract = displayContractType();
    if (contract?.contractType !== undefined) {
      setValue(
        'inputContractType' + suffixRole,
        contract?.contractType,
        shouldTouchAndValidate
      );
    }
    setIsContractTypeVisible(contract.isVisible);
  }, [
    inputEmployerActivitySector,
    inputProfessionOrSituation,
    inputEmployerDate,
    listTypesContrat,
    isContractTypeVisible,
  ]);

  // TRAITEMENT DES RETOURS DE WEBSERVICES
  useEffect(() => {
    const newList = resultGetCountries.data?.map((pays) => {
      return { id: pays.code, label: pays.label };
    });
    setListCountries(newList);
  }, [resultGetCountries.data]);

  useEffect(() => {
    const newList = resultGetDepartments.data?.map((departement) => {
      return { id: departement.code, label: departement.label };
    });
    setListDepartments(newList);
  }, [resultGetDepartments.data]);

  useEffect(() => {
    const newList = resultGetDepartmentsByCity.data?.map((departement) => {
      return { id: departement.code, label: departement.label };
    });
    setListDepartmentsByCity(newList);
  }, [resultGetDepartmentsByCity.data]);

  useEffect(() => {
    setNewCitiesList();
  }, [resultGetCities.data]);

  useEffect(() => {
    const newList = resultGetCitiesByZipCd.data?.map((ville) => {
      return { id: ville.code, label: ville.label };
    });
    setListCitiesZipCd(newList);
    setIsCitiesByZipCdReady(!resultGetCitiesByZipCd.isError);
  }, [resultGetCitiesByZipCd.data, resultGetCitiesByZipCd.isError]);

  useEffect(() => {
    if (
      resultGetValidationSirenNumber.isUninitialized ||
      resultGetValidationSirenNumber.isLoading
    ) {
      return;
    }
    const dataError =
      (resultGetValidationSirenNumber?.error as { data: any }).data ?? [];
    if (dataError[0]) {
      setErrorSirenNumber(dataError[0]?.error);
    }
  }, [resultGetValidationSirenNumber.error]);

  const setNewCitiesList = () => {
    const newList = resultGetCities.data?.map((ville) => {
      return { id: ville.code, label: ville.label };
    });
    setListCities(newList);
    setIsCitiesReady(true);
  };

  const isRenting = (): boolean => {
    const housingCd = getValues('housingCd' + suffixRole);
    // Si loyer ou prêt immobiler, ce montant doit être > 0
    return housingCd === LOCATAIRE || housingCd === PROPRIETAIRE_AVEC_PRET;
  };

  const haveToBe16 = (): boolean => {
    const housingCd = getValues('housingCd' + suffixRole);
    return !(housingCd === AUTRES || housingCd === LOGE_PAR_FAMILLE);
  };

  const onNoneButtonClick = (
    fieldName: any,
    onChange: (arg0: boolean) => void
  ) => {
    const currentValue = getValues(fieldName);
    onChange(currentValue !== 0);
    setValue(fieldName, currentValue === 0 ? '' : 0, {
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onMonthlyRentChange = (
    onChange: (...event: any) => void,
    buttonName: `${string}`,
    newValue: string | number
  ) => {
    onChange(newValue);
    if (isRenting()) {
      setValue(buttonName, false);
    } else {
      setValue(buttonName, newValue?.toString() === '0');
    }
  };

  const onAmountChange = (
    onChange: (arg0: any) => void,
    noneButtonName: any,
    newValue: { toString: () => string }
  ) => {
    onChange(newValue);
    setValue(noneButtonName, newValue?.toString() === '0');
  };

  const validateMonthlyRentAmount = (
    newValue: string | number | boolean
  ): boolean => {
    if (!validatePricing(newValue)) {
      return false;
    }
    if (isRenting()) {
      return Number.parseInt(newValue + '', 10) > 0;
    }
    return true;
  };

  const onMonthlyRentButtonCheckClick = (
    name: string,
    onChange: (...event: unknown[]) => void
  ) => {
    onNoneButtonClick(name, onChange);
    if (isRenting()) {
      onChange(false);
    }
  };

  const isMonthlyRentButtonCheckDisabled = (): boolean => {
    const shouldBeDisabled = isRenting();
    if (shouldBeDisabled && getValues('monthlyRentAmount' + suffixRole) === 0) {
      setValue('monthlyRentAmount' + suffixRole, '');
    }
    return shouldBeDisabled;
  };

  const onValidateInputBirthDate = (value: string): boolean => {
    if (!value) {
      return false;
    }
    const birthDate = dayjs(
      value.toString().replace(/ /g, ''),
      DATE_FORMAT,
      true
    );
    const age = dayjs().diff(birthDate, 'years');
    return age >= 18 && age <= 100;
  };

  const onValidateInputAccomodationDate = (value: string): boolean => {
    const accomodationDate = dayjs(
      value.toString().replace(/ /g, ''),
      DATE_FORMAT_WO_DAYS,
      true
    );
    const birthDate = dayjs(
      getValues('inputBirthDate' + suffixRole)
        ?.toString()
        .replace(/ /g, ''),
      DATE_FORMAT,
      true
    );
    const validDate = haveToBe16()
      ? birthDate.add(16, 'year')
      : birthDate.add(1, 'month');
    return (
      accomodationDate.isValid() &&
      accomodationDate.isBetween(validDate, dayjs(), 'month', '[]')
    );
  };

  const onValidateAccountOpeningDate = (value: string): boolean => {
    const mainBankAccountDate = dayjs(
      value.toString().replace(/ /g, ''),
      DATE_FORMAT_WO_DAYS,
      true
    );
    const proprietaireCompte = getValues('inputAccountOwner');
    const birthDate = dayjs(
      getValues(
        'inputBirthDate' +
          (proprietaireCompte === ProprietaireCompte.CO_EMPRUNTEUR
            ? '_CTI'
            : '_TIT')
      )
        ?.toString()
        .replace(/ /g, ''),
      DATE_FORMAT,
      true
    );
    return (
      mainBankAccountDate.isValid() &&
      mainBankAccountDate.isBetween(
        birthDate.add(13, 'years'),
        dayjs(),
        'month',
        '[]'
      )
    );
  };

  const onValidateInputEmployerDate = (value: string): boolean => {
    const employerDate = dayjs(
      value.toString().replace(/ /g, ''),
      DATE_FORMAT_WO_DAYS,
      true
    );
    const birthDate = dayjs(
      getValues('inputBirthDate' + suffixRole)
        ?.toString()
        .replace(/ /g, ''),
      DATE_FORMAT,
      true
    );
    // L'emprunteur doit avoir au min 16 ans
    // date de naissance : dd/03/2000
    // date chez l'employeur depuis : 03/2016
    return (
      employerDate.isValid() &&
      employerDate.isBetween(birthDate.add(16, 'years'), dayjs(), 'month', '[]')
    );
  };

  const isDateMMYYYYMoreThanXMonths = (
    value: string,
    xMonths: number
  ): boolean => {
    const dateMMYYYYTotest = dayjs(
      value.toString().replace(/ /g, ''),
      DATE_FORMAT_WO_DAYS,
      true
    );
    return dayjs().diff(dateMMYYYYTotest, 'month') > xMonths ? true : false;
  };

  const onValidateInputZipCd = (value: string): boolean => {
    return zipCodeRegexp.test(value) && !resultGetCitiesByZipCd?.isError;
  };

  const onValidateInputEmployerZipCode = (value: string): boolean => {
    return (
      zipCodeRegexp.test(value) && !resultGetCitiesByEmployerZipCode?.isError
    );
  };

  const onValidateEvidenceNumber = (value: string): boolean => {
    return validateEvidenceNumber({
      value,
      evidenceSubTypeJDID: getValues(
        'evidenceSubTypeJDID' + suffixRole
      )?.toString(),
    });
  };

  const validatePricing = (value: string | number | boolean): boolean => {
    return (
      Number.isInteger(Number(value)) &&
      Number.parseInt(value + '', 10) >= 0 &&
      Number(value) < 100000
    );
  };

  const buildListSecteursActiviteToDisplay = (): IOption[] => {
    const listToBuild: IOption[] = [];
    secteursActivite?.forEach((value) => {
      const secteurActivite: IOption = {
        id: value.secteur.id,
        label: value.secteur.label,
      };
      listToBuild.push(secteurActivite);
    });
    return listToBuild;
  };

  const displayEmployerInformations = (): boolean => {
    return seuilMontantCreditPourAffichageInfosEmployeur &&
      inputLoanAmount > seuilMontantCreditPourAffichageInfosEmployeur
      ? true
      : false;
  };

  const displayContractType = (): IDisplayContract => {
    // On met aussi une valeur en dur pour le type de contrat si la zone n'est pas visible
    if (
      inputEmployerActivitySector === 'ACO' ||
      inputEmployerActivitySector === 'PLI'
    ) {
      return { isVisible: false, contractType: '' };
    }
    if (
      inputEmployerActivitySector === 'SPR' &&
      inputEmployerDate &&
      isDateMMYYYYMoreThanXMonths(inputEmployerDate, 60)
    ) {
      return { isVisible: false, contractType: 'I' };
    }
    if (
      inputEmployerActivitySector === 'SPU' &&
      inputEmployerDate &&
      isDateMMYYYYMoreThanXMonths(inputEmployerDate, 36)
    ) {
      return { isVisible: false, contractType: 'I' };
    }
    if (
      inputEmployerActivitySector === 'AUT' &&
      ['90', '91', '92', '94', '95', '98'].includes(inputProfessionOrSituation)
    ) {
      return { isVisible: false, contractType: '' };
    }
    if (
      inputEmployerActivitySector === 'SAG' &&
      inputProfessionOrSituation === '1'
    ) {
      return { isVisible: false, contractType: '' };
    }
    return {
      isVisible:
        !!inputEmployerDate &&
        dayjs(
          inputEmployerDate.toString().replace(/ /g, ''),
          DATE_FORMAT_WO_DAYS,
          true
        ).isValid(),
    };
  };

  const buildListProfessionsToDisplay = (
    secteurActiviteValue: string
  ): IOption[] | undefined => {
    return secteursActivite?.find(
      (value) => value.secteur.id === secteurActiviteValue
    )?.professions;
  };

  const buildListTypesContratToDisplay = (
    secteurActiviteValue: string
  ): IOption[] | undefined => {
    return secteursActivite?.find(
      (value) => value.secteur.id === secteurActiviteValue
    )?.typesContrat;
  };

  const onChangeSirenNumber = (
    newValue: string | number,
    onChange: (arg0: string) => void
  ) => {
    setErrorSirenNumber(null);
    if (newValue.toString().length === 9) {
      triggerGetValidationSirenNumber(newValue.toString());
    }
    onChange(newValue.toString());
  };

  const removeTargetedInputsFormInSessionStorage = (target: string): void => {
    const localStore = sessionStorage.getItem('form-informations');
    const parsedLocalStore = localStore ? JSON.parse(localStore) : null;
    if (parsedLocalStore) {
      Object.entries(parsedLocalStore).forEach(([key, value]) => {
        if (key.endsWith(target)) {
          unregister(key);
        }
      });
    }
  };

  const clearPersonInformations = (): void => {
    // Dans le cas où l'utilisateur choisit d'abord emp + coemp
    // => données coemp alimentées
    // Si l'utilisateur revient sur son choix et prend uniquement un emp,
    // il faut supprimer les données coemp précédemment stockées.
    if (!hasCoborrower()) {
      // suppression des données coemp du store
      dispatch(clearPersonCoEmprunteur());
      // suppression des input coemp du formulaire stocké en sessionStorage
      removeTargetedInputsFormInSessionStorage(personTypes.RoleCd.COBORROWER);
    }
  };

  return {
    control,
    onValidateInputBirthDate,
    onValidateEvidenceNumber,
    onValidateInputAccomodationDate,
    onValidateInputEmployerDate,
    onValidateInputZipCd,
    onValidateAccountOpeningDate,
    onValidateInputEmployerZipCode,
    onChangeSirenNumber,
    errorSirenNumber,
    isSirenNumberLoading: resultGetValidationSirenNumber?.isFetching,
    listCivility,
    listCountries,
    listDepartments,
    listCities,
    listCitiesZipCd,
    listSecteursActivite,
    listTypesContrat,
    listProfessions,
    listStatutsLocaux,
    listStatutsProfessionnels,
    isFranceSelected,
    isCitiesReady,
    isCitiesByZipCdReady,
    isCitiesByZipCdLoading: resultGetCitiesByZipCd?.isFetching,
    isCitiesByEmployerZipCdLoading:
      resultGetCitiesByEmployerZipCode?.isFetching,
    isDepartmentsLoading: resultGetDepartments?.isFetching,
    isCityEnabled,
    onAmountChange,
    onNoneButtonClick,
    onMonthlyRentChange,
    onMonthlyRentButtonCheckClick,
    isMonthlyRentButtonCheckDisabled,
    validateMonthlyRentAmount,
    validatePricing,
    isActivityTypeVisible,
    isArtisanCommercant,
    isNameEmployerVisible,
    isZipCdEmployerVisible,
    isContractTypeVisible,
    clearPersonInformations,
    listSituationsFamiliale,
  };
};

export default useInformationsForm;
