import { IDocumentsUploaded } from '@store';

enum EvidenceType {
  JDDO = 'JUSTIFICATIF_DOMICILE',
  JDOP = 'JUSTIFICATIF_OPERATION',
  JDRE = 'JUSTIFICATIF_REVENUS',
  JBAN = 'JUSTIFICATIF_BANCAIRE',
  JDID = 'JUSTIFICATIF_IDENTITE',
}
enum EvidenceSubType {
  // JUSTIFICATIF_DOMICILE
  QEE6 = 'QUITTANCE_ENERGIE_MOINS_6M',
  EAEG = 'ECHEANCIER_ENERGIE_MOINS_3M',
  FTE6 = 'FACTURE_TEL_INTERNET_MOINS_6M',
  QDLP = 'QUITTANCE_LOYER_MOIS_PREC',
  BDLO = 'BAIL_LOCATION',
  TFON = 'DERNIER_AVIS_TAXE_FONCIERE',
  DAIP = 'DERNIER_AVIS_IMPOSITION',
  TPLR = 'TITRE_PROPRIETE_RESID_MOINS_6M',
  ADAH = 'ATTEST_ASSU_HABITAT_MOINS_6M',
  CMUT = 'CERTIFICAT_MUTATION_MOINS_3M',
  EEGE = 'ECHEANCIER_ENERGIE_MOINS_12M',
  // JUSTIFICATIF_REVENUS
  DBSA = 'DERNIER_BULLETIN_SALAIRE',
  BDRE = 'BULLETIN_RETRAITE',
  ADIM = 'DERNIER_AVIS_IMPOT',
  // JUSTIFICATIF_IDENTITE
  CARTE_IDENTITE_NATIONALE = 'CARTE_IDENTITE_NATIONALE',
  PASSEPORT = 'PASSEPORT',
  TITRE_DE_SEJOUR = 'TITRE_DE_SEJOUR',
  BDCO = 'BON_COMMANDE',
  // JUSTIFICATIF_BANCAIRE
  RIBS = 'RIB_SANS_ADRESSE',
}

const useTransformDocumentsUploaded = () => {
  const transformDocumentsUploaded = (
    documentsUploaded: IDocumentsUploaded[]
  ): IDocumentsUploaded[] => {
    const transformedDocumentsUploaded: IDocumentsUploaded[] = [];
    documentsUploaded.forEach((documentUploaded) => {
      const transformedDocumentUploaded = { ...documentUploaded };
      transformedDocumentUploaded.typePiece =
        Object.keys(EvidenceType)[
          Object.values(EvidenceType).indexOf(
            transformedDocumentUploaded?.typePiece as EvidenceType
          )
        ];
      transformedDocumentUploaded.sousTypePiece =
        Object.keys(EvidenceSubType)[
          Object.values(EvidenceSubType).indexOf(
            transformedDocumentUploaded?.sousTypePiece as EvidenceSubType
          )
        ];
      if (transformedDocumentUploaded.typePiece === 'JDOP') {
        transformedDocumentUploaded.typeEmprunteur = 'NO_EMPRUNTEUR_TYPE';
        transformedDocumentUploaded.finalisation = true;
      }
      if (transformedDocumentUploaded.typePiece === 'JBAN') {
        transformedDocumentUploaded.typeEmprunteur = 'NO_EMPRUNTEUR_TYPE';
      }

      transformedDocumentsUploaded.push(transformedDocumentUploaded);
    });
    return transformedDocumentsUploaded;
  };

  return { transformDocumentsUploaded };
};
export default useTransformDocumentsUploaded;
