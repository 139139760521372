import * as messages from './messages';
import { Icons, Input, Title } from '@components';
import {
  FormWrapper,
  ItemWrapper,
  StyledTextTooltip,
  StyledTitleTooltip,
  StyledTooltip,
  TitleWrapper,
  WrapperInputMobilePhone,
} from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { selectPerson, useAppSelector, personTypes } from '@store';
import { isEmailValide, phoneMask, mobilePhoneRegExp } from '@utils';
import { MobileKeyboardType } from '@types';

const ContactCoBorrower: React.FC = () => {
  const { control } = useFormContext();

  const persons = useAppSelector(selectPerson);
  const person = persons?.coemprunteur;
  const suffixRole = '_' + personTypes.RoleCd.COBORROWER;

  return (
    <FormWrapper>
      <TitleWrapper>
        <Title level={2}>{messages.CONTAINER_TITLE}</Title>
      </TitleWrapper>
      <ItemWrapper>
        <Controller
          control={control}
          name={'inputEmail' + suffixRole}
          defaultValue={person?.contactInformation?.email}
          rules={{
            required: true,
            validate: isEmailValide,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              name={name}
              inputMode={MobileKeyboardType.EMAIL}
              label={messages.INPUT_EMAIL}
              placeholder={messages.PLACEHOLDER_EMAIL}
              value={value}
              isValid={!invalid}
              isTouched={isTouched}
              onChange={onChange}
              onBlur={onBlur}
              isSubmitted={isSubmitted}
              inputRef={ref}
              error={isTouched && invalid ? messages.ERROR_EMAIL : ''}
            />
          )}
        />
      </ItemWrapper>
      <WrapperInputMobilePhone>
        <Controller
          control={control}
          name={'inputMobilePhoneNumber' + suffixRole}
          defaultValue={person?.contactInformation?.mobilePhoneNumber}
          rules={{
            required: true,
            pattern: mobilePhoneRegExp,
          }}
          render={({
            field: { onChange, value, name, onBlur, ref },
            fieldState: { isTouched, invalid },
            formState: { isSubmitted },
          }) => (
            <Input
              inputMode={MobileKeyboardType.TEL}
              name={name}
              label={messages.INPUT_MOBILE_PHONE_NUMBER}
              placeholder={messages.PLACEHOLDER_MOBILE_PHONE_NUMBER}
              value={value}
              isTouched={isTouched}
              isValid={!invalid}
              onBlur={onBlur}
              inputRef={ref}
              onChange={onChange}
              mask={phoneMask}
              maskChar=" "
              isSubmitted={isSubmitted}
              error={
                isTouched && invalid ? messages.ERROR_MOBILE_PHONE_NUMBER : ''
              }
            />
          )}
        />
      </WrapperInputMobilePhone>
      <StyledTooltip
        skin="advice"
        hasArrowDown={false}
        icon={Icons.InfoOutlinesIcon}
        isVisible={true}
      >
        <StyledTitleTooltip level={2}>
          {messages.TOOLTIP_CONTACT_TITLE}
        </StyledTitleTooltip>
        <StyledTextTooltip>{messages.TOOLTIP_CONTACT_NOTE}</StyledTextTooltip>
      </StyledTooltip>
    </FormWrapper>
  );
};

export default ContactCoBorrower;
