import React from 'react';
import { IFile, StatusCd } from '@types';
import { AnalyseDocument } from 'types/document';
import {
  WrongIdOnEvidence,
  ExpiredEvidence,
  UploadFailed,
  UnavailableService,
  UnreadableEvidence,
} from './alerts';

interface IProps {
  ocrData: AnalyseDocument | undefined;
  blockName: string;
  isCheckedWithAddress: boolean;
  isMaxReuploadAttempt: boolean;
  setIsModalClosable: (arg: boolean) => void;
  onReupload: () => void;
  onAccept: () => void;
  onUnavailableService: () => void;
  evidenceSubTypeCd: string;
  files: IFile[];
  redirigerAccueil: () => void;
  isSwitchDeviceTablet?: boolean;
  configuration: any;
}
export const OcrError: React.FC<IProps> = ({
  ocrData,
  isMaxReuploadAttempt,
  setIsModalClosable,
  onReupload,
  onAccept,
  onUnavailableService,
  redirigerAccueil,
}): React.ReactElement => {
  let content = <></>;
  switch (ocrData && ocrData.statut) {
    case StatusCd.EXPIRED_EVIDENCE:
      content = (
        <ExpiredEvidence
          redirectToPopHome={redirigerAccueil}
          onReupload={onReupload}
          onAccepted={onUnavailableService}
          isMaxReuploadAttempt={isMaxReuploadAttempt}
          isExpired
        />
      );

      break;
    case StatusCd.OUTDATED_EVIDENCE:
      content = (
        <ExpiredEvidence
          redirectToPopHome={redirigerAccueil}
          onReupload={onReupload}
          onAccepted={onAccept}
          isMaxReuploadAttempt={isMaxReuploadAttempt}
          isExpired={false}
        />
      );

      break;

    case StatusCd.INCONSISTENCY_IDENTITY:
      content = (
        <WrongIdOnEvidence
          onReupload={onReupload}
          onAccepted={onAccept}
          isMaxReuploadAttempt={isMaxReuploadAttempt}
        />
      );
      break;
    case StatusCd.ANALYZE_KO:
      setIsModalClosable(false);
      if (isMaxReuploadAttempt) {
        content = (
          <UnavailableService handleUnavailableService={onUnavailableService} />
        );
      } else {
        content = <UploadFailed onButtonClick={onReupload} />;
      }
      break;
    case StatusCd.SERVER_ERROR:
      setIsModalClosable(false);
      content = (
        <UnavailableService handleUnavailableService={onUnavailableService} />
      );

      break;
    case StatusCd.UNREADABLE:
      content = (
        <UnreadableEvidence
          onReupload={onReupload}
          onAccepted={onAccept}
          status={ocrData?.statut}
          isMaxReuploadAttempt={isMaxReuploadAttempt}
        />
      );
      break;
    default:
      break;
  }

  return content;
};
