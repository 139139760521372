export const FORMATION_MODE_BADGE = 'MODE FORMATION';
export const CONTAINER_TITLE = 'Réponse de principe';
export const CONTAINER_SUBTITLE =
  'Quel type de réponse souhaitez-vous simuler ?';
export const CONTAINER_TEXT =
  'Toutes les réponses (même défavorable) permettent une ';
export const CONTAINER_TEXT_BOLD = 'signature papier';
export const GREEN_SUBTITLE = 'Avis Favorable';
export const GREEN_TEXT =
  'La demande de votre client est pré-acceptée sous réserve du contrôle des pièces justificatives.';
export const ORANGE_SUBTITLE = 'Avis en attente';
export const ORANGE_TEXT =
  'La demande de votre client nécessite une étude approfondie par nos experts.';
export const RED_SUBTITLE = 'Notre décision';
export const RED_TEXT =
  "En l'état des informations transmises à ce stade, nous ne pouvons répondre favorablement à la demande de votre client.";
