export enum typeAppareil {
  ORDINATEUR = 'ORDINATEUR',
  MOBILE = 'MOBILE',
}

export enum EventSwitchDevice {
  CREATION = 'CREATION',
  CONNEXION = 'CONNEXION',
  NO_FERMETURE = 'NO_FERMETURE',
  FERMETURE = 'FERMETURE',
}

interface IEventSwitchDevice {
  label: string;
  isFinal: boolean;
}
export const listeEvenements: IEventSwitchDevice[] = [
  { label: 'CREATION', isFinal: false },
  { label: 'CONNEXION', isFinal: false },
  { label: 'FERMETURE', isFinal: true },
];

export const FINAL_EVENTS_SWITCH_DEVICE = listeEvenements
  .filter((e) => e.isFinal === true)
  .map((e) => e.label);
