import styled from 'styled-components';
import { Breadcrumb, Centering } from '@components';
import { MAX_WIDTH_SIZE, MIN_WIDTH_SIZE } from '@utils';

export const AppWrapper = styled.div.attrs(
  (props: { isSimu: boolean }) => props
)`
  padding-top: ${(props) => (props.isSimu ? '0' : '1.4rem')};
  display: flex;
  min-height: calc(100vh);
  min-width: ${() => MIN_WIDTH_SIZE + 'px'};
  flex-direction: column;
  color: ${({ theme }) => theme.variables.colors.chartColors.dark_700};
`;

export const StyledCentering = styled(Centering)`
  min-height: calc(100vh - 1.4rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BreadcrumbWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  max-width: 70.1rem;
  flex-grow: 1;
`;

export const FooterWrapper = styled.div`
  .banner-shown & div {
    margin-bottom: 8rem;
  }
`;

export const RouteWrapper = styled.div`
  flex-grow: 1;
  margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
`;

export const FormationModeBadgeWrapper = styled.div`
  min-height: ${({ theme }) => theme.variables.layout.spacing.spacing_m};
  width: fit-content;

  @media (max-width: ${() => MAX_WIDTH_SIZE + 'px'}) {
    margin-top: ${({ theme }) => theme.variables.layout.spacing.spacing_xs};
  }
`;
