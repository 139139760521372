export const TITLE_MODAL_QR_CODE =
  'Téléchargez les pièces justificatives depuis votre smartphone';
export const TITLE_MODAL_QR_CODE_SWITCHED =
  'Téléchargement en cours sur votre smartphone';
export const SUBTITLE_MODAL_QR_CODE =
  'Vous pourrez ajouter les pièces ou les prendre en photo.';
export const SUBTITLE_INFOBULLE_COMMENT_CA_MARCHE = 'Comment ça marche?';

export const TEXT_INFOBULLE_MOBILE = 'Munissez-vous de votre mobile.';
export const TEXT_INFOBULLE_FLASHE = 'Flashez le QR Code ci-dessous. ';
export const TEXT_INFOBULLE_PHOTO_OU_TELECHARGEZ =
  'Prenez en photo ou téléchargez vos documents';
export const LABEL_SUBMIT_BUTTON = 'Poursuivre';
export const INFO_TITLE = 'Complétez votre dossier dès maintenant';
export const LABEL_BUTTON_OPEN_MODAL = "Changer d'appareil";
export const TITLE_MODAL_CONFIRMATION_ARRET_PARCOURS =
  'Attention: téléchargement en cours sur le mobile';
export const TEXT_MODAL_CONFIRMATION_ARRET_PARCOURS_ARRET_PARCOURS =
  "Si vous cliquez sur Poursuivre sur PC, le téléchargement en cours est annulé si la pièce n'a pas été validée. ";
export const TEXT_MODAL_CONFIRMATION_ARRET_PARCOURS_CANCEL =
  'Si vous cliquez sur Poursuivre sur mobile, reprenez votre mobile et continuez le téléchargement.';
export const POURSUIVRE_PC_MODAL_CONFIRMATION_ARRET_PARCOURS =
  'Poursuivre sur PC';
export const POURSUIVRE_MOBILE_MODAL_CONFIRMATION_ARRET_PARCOURS =
  'Poursuivre sur mobile';
export const POURSUIVRE = 'Poursuivre';
export const TITLE_MODAL_TELECHARGEMENT_REUSSI =
  'Téléchargement des pièces justificatives réussi';
export const TEXT_MODAL_TELECHARGEMENT_REUSSI_TELECHARGEMENT_REUSSI =
  'Le téléchargement des pièces justificatives depuis la tablette a réussi.';
export const TEXT_MODAL_TELECHARGEMENT_REUSSI_ETAPE_SUIVANTE =
  "Vous pouvez passer à l'étape suivante.";
