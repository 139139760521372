import { selectOffer, useAppSelector } from '@store';

const useBorrowers = () => {
  const offer = useAppSelector(selectOffer);

  const hasCoborrower = () => offer?.inputBorrowers === '2';

  return {
    hasCoborrower,
  };
};

export default useBorrowers;
