import {
  IEvidenceType,
  IFile,
  IImagesFile,
  ImageStatus,
  IOcr,
  IUploadDocumentsResponse,
} from '@types';
import { compressImage } from '@utils';
import { Buffer } from 'buffer';

const IMAGE_COMPRESSION_LEVEL = 0.85;
const IMAGE_COMPRESSION_SIZE_THRESHOLD = 100000; // Max size in octets that triggers the compression
const IMAGE_COMPRESSION_MAX_PIXEL_SIZE = 2000;
const IMAGE_DPI_THRESHOLD = 1000; // Increase DPI if below 1000 pixels
const IMAGE_DPI_OCR = 250;
const useFileUploadRegex = new RegExp(`^(.*)(\\..*)$`);

/*
 * Messages Upload
 *
 *
 */
const moreThanMaxSizeError = (maxFileSize: number) =>
  `La taille du fichier est supérieure à ${maxFileSize} Mo.`;
const moreThanMaxPagesError = (maxFilePages: number) =>
  `Le nombre de pages du fichier est supérieur à ${maxFilePages} pages.`;
const forbiddenFormatError = "Le format du fichier n'est pas accepté.";

export const openPdfFile = (pdfContent: string) => {
  const bufferedPdfContract = Buffer.from(pdfContent ?? '', 'base64');
  const blob = new Blob([bufferedPdfContract], {
    type: 'application/pdf;base64',
  });
  const pdfObjectUrl = URL.createObjectURL(blob);

  setTimeout(() => {
    window.open(pdfObjectUrl, '_blank');
  }, 1);
};

export const getUniqueName = (
  name = '',
  existingFiles: IFile[],
  fileId?: string,
  prohibitedFilename?: string
): string => {
  let newFileName =
    // Filtres requis par la sécurité sur le nom du fichier (taille max, retrait des caractères spéciaux)
    (name
      .replace(/^(.{1,170}).*\.[a-zA-Z]{1,10}$/, '$1')
      .replace(/[^a-zA-Z0-9]/g, '') ?? 'filename') +
    // File extension
    name.replace(/^.*\.([a-zA-Z]{1,10})$/, '.$1');

  const filenames = existingFiles.map(
    (file) => (file && file.id !== fileId ? file.name : null) ?? ''
  );

  if (
    filenames.includes(newFileName) ||
    (prohibitedFilename && prohibitedFilename === name)
  ) {
    let nbFile = 0;
    do {
      nbFile += 1;
    } while (
      filenames.includes(
        newFileName.replace(useFileUploadRegex, `$1_(${nbFile})$2`)
      )
    );

    newFileName = newFileName.replace(useFileUploadRegex, `$1_(${nbFile})$2`);
  }

  return newFileName;
};

export const getRenamedFile = (file: File, newName: string): File => {
  const { size, type } = file;

  const renamedFile = new File([file], newName, {
    type,
  });

  // On doit faire muter l'objet ici sinon si on recrée un File pour le renommer,
  // la taille du fichier est recalculée et du coup ça casse les tests où on mock la taille du fichier
  Object.defineProperty(renamedFile, 'size', {
    value: size,
    writable: true,
  });

  return renamedFile;
};

export const constructByteArray = async (file: File): Promise<number[]> => {
  if (!file) {
    return [];
  }
  const bufferArray1 = await file.arrayBuffer();
  const ui8 = new Uint8Array(bufferArray1);
  return [...ui8];
};

// Intercepte les actions et déclanche les calls api
export const uploadFile = async (
  { id, file }: IFile,
  setImage: (image: IImagesFile) => void,
  sendDocument: (file: File, id: string) => Promise<IUploadDocumentsResponse>,
  updateFile: (file: File, id: string) => File,
  setFileUploadSuccess: (id: string) => any,
  setFileUploadFailure: (id: string, error: any) => any,
  onFailure: (error: any) => any,
  ocrParameters: IOcr,
  evidenceSubType?: IEvidenceType
): Promise<HTMLImageElement | undefined> => {
  const { type, size } = file;

  const isImage = type.match(/^image\//) != null;
  const isImageTiff = type === 'image/tiff';
  const isImageGIF = type === 'image/gif';
  const isPDF = type === 'application/pdf';
  const isBDCO = evidenceSubType?.id === 'BDCO';

  const image: HTMLImageElement | undefined = new Image();

  const getMaxPdfSize = (isBDCO = false) => {
    return isBDCO
      ? ocrParameters?.blocks?.blocksCommande?.maxPdfSize ??
          ocrParameters?.maxPdfSize
      : ocrParameters?.maxPdfSize;
  };

  // si c'est une image sauf au format Tiff et GIF
  if (isImage && !isImageTiff && !isImageGIF) {
    // de plus de  $maxFileSize Mo
    if (size > 1048576 * ocrParameters.maxFileSize) {
      setFileUploadFailure(id, moreThanMaxSizeError(ocrParameters.maxFileSize));
    } else {
      compressImage(
        file,
        (compressedFile: File | null) => {
          if (compressedFile) {
            const newFile = updateFile(compressedFile, id);
            sendDocument(newFile, id)
              .then(() => setFileUploadSuccess(id))
              .catch((error) => {
                console.log(error);
                let errorMessage = '';
                if ('data' in error && Array.isArray(error?.data)) {
                  errorMessage = error.data[0].error_description ?? '';
                }
                setFileUploadFailure(id, errorMessage);
                onFailure(error);
              });
          }
        },
        {
          compressionSizeThreshold: IMAGE_COMPRESSION_SIZE_THRESHOLD,
          compressionLevel: IMAGE_COMPRESSION_LEVEL,
          maxPixelSize: IMAGE_COMPRESSION_MAX_PIXEL_SIZE,
          dpiThreshold: IMAGE_DPI_THRESHOLD,
          dpiOCR: IMAGE_DPI_OCR,
        },
        (compressedImage: HTMLImageElement) => {
          setImage({
            id,
            images: Array.of(compressedImage),
            status: ImageStatus.VISIBLE,
            file,
            fileType: evidenceSubType?.id ?? '',
          });
        }
      );
    }
  } else if (isPDF) {
    // De plus de $maxFileSize Mo
    if (size > 1048576 * getMaxPdfSize(isBDCO)) {
      setFileUploadFailure(id, moreThanMaxSizeError(getMaxPdfSize(isBDCO)));
    } else {
      // On lit le PDF
      const reader = new FileReader();
      reader.onload = async () => {
        if (reader.result) {
          const nbOfPages =
            // @ts-ignore
            reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 1;
          // Si le pdf contient plus de $maxFilePages pages
          if (
            nbOfPages >
            (isBDCO
              ? ocrParameters.maxFilePagesBDCO
              : ocrParameters.maxFilePages)
          ) {
            setFileUploadFailure(
              id,
              moreThanMaxPagesError(
                isBDCO
                  ? ocrParameters.maxFilePagesBDCO
                  : ocrParameters.maxFilePages
              )
            );
          } else {
            const newFile = updateFile(file, id);
            sendDocument(newFile, id)
              .then((data) => {
                setFileUploadSuccess(id);
                const images: HTMLImageElement[] = data?.preview_images?.map(
                  (imageBytes) => {
                    const image = new Image();
                    const imageSrc = `data:image/jpg;base64,${imageBytes}`;
                    image.src = imageSrc;
                    return image;
                  }
                );
                setImage({
                  id,
                  images: images ?? [],
                  status: ImageStatus.VISIBLE,
                  file,
                  fileType: evidenceSubType?.id ?? '',
                });
              })
              .catch((error) => {
                console.log(error);
                let errorMessage = '';
                if ('data' in error && Array.isArray(error?.data)) {
                  errorMessage = error.data[0].error_description ?? '';
                }
                setFileUploadFailure(id, errorMessage);
                onFailure(error);
              });
          }
        }
      };
      reader.readAsBinaryString(file);
    }
  } else {
    setFileUploadFailure(id, forbiddenFormatError);
  }
  return Promise.resolve(image);
};
