import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  selectConfiguration,
  updateDocumentsEmprunteur,
  updatePersonEmprunteur,
  useAppDispatch,
  useAppSelector,
  useLazyGetCitiesByZipCdQuery,
  useSendModifyBorrowerMutation,
} from '@store';
import { WrapperBody, WrapperSubTitle } from './styles';
import { FormEditableField, Input, Select, Subtitle } from '@components';
import * as messages from './messages';
import { addressRegExp, capitalize, cityRegExp, zipCodeRegexp } from '@utils';
import { FormProvider, useForm } from 'react-hook-form';
import Common from './Common';
import {
  IModifyBorrowerRequest,
  IDocument,
  EvidenceType,
  IAddress,
  AnalyseDocument,
  IOption,
} from '@types';
import { getformattedCityName } from 'containers/Informations/InformationsUtils';

dayjs.extend(customParseFormat);

export interface IProps {
  onValidate?: () => void;
  ocrData?: AnalyseDocument;
  borrowerType?: string;
  isMaxReuploadAttempt?: boolean;
  onReupload: () => void;
}

export const FormOcrDom: React.FC<IProps> = ({
  onValidate = () => undefined,
  ocrData,
  borrowerType,
  isMaxReuploadAttempt = false,
  onReupload,
}) => {
  useEffect(() => {
    if (ocrData?.adresse?.cod_postal) {
      triggerGetCitiesByZipCd(ocrData.adresse.cod_postal).then((data) => {
        const villes = data?.data?.map((ville) => {
          return ville.label;
        });
        if (
          villes &&
          ocrData?.adresse?.ville &&
          !villes.includes(ocrData.adresse.ville)
        ) {
          const newList = data?.data?.map((ville) => {
            return { id: ville.code, label: ville.label };
          });
          setCityList(newList ?? []);
          methods.setValue('city', '');
          setIsCityInvalide(true);
        }
      });
    }
  }, []);

  const storeDispatch = useAppDispatch();
  const handleFormSubmit = (data: IAddress) => {
    onSendInfoDom(data);
    // Save document to store
    const document: IDocument = {
      evidenceSubTypeCd: ocrData?.soustype_piece,
      evidenceTypeCd:
        EvidenceType[ocrData?.type_piece as keyof typeof EvidenceType],
      evidenceNb: ocrData?.numero_preuve,
      ocrId: ocrData?.ocr_id,
    };
    // store information to store
    storeDispatch(
      updatePersonEmprunteur({
        billingAddressInformation: data,
      })
    );
    storeDispatch(updateDocumentsEmprunteur(document));

    onValidate();
  };

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const configuration = useAppSelector(selectConfiguration);
  const { souscriptionId } = { ...configuration };
  const [sendModifyInfoBorrower, responseModifyInfoBorrower] =
    useSendModifyBorrowerMutation();

  const onSendInfoDom = (data: IAddress) => {
    const buildArgs = () => {
      return {
        etape: 'OCR_ADRESSE',
        typeEmprunteur: borrowerType ?? '',
      };
    };

    const buildBody = (): IModifyBorrowerRequest => {
      return {
        adresse_postale: data?.streetAddress,
        code_postal: data?.zipCd,
        ville: getformattedCityName(data?.city),
      };
    };

    const modifyInfoBorowerRequestArgs = buildArgs();
    const modifyOcrBorrowerRequestBody = buildBody();

    sendModifyInfoBorrower({
      numeroSouscription: souscriptionId ?? '',
      arg: modifyInfoBorowerRequestArgs,
      modifyBorrowerRequest: modifyOcrBorrowerRequestBody,
    }).catch((error) => console.log(error));
  };

  const getIncompleteReadBo = () =>
    !ocrData?.adresse?.adresse_complete ||
    !ocrData?.adresse?.cod_postal ||
    !ocrData?.adresse?.ville ||
    isCityInvalide;

  const [cityList, setCityList] = useState<IOption[]>(
    ocrData?.adresse?.ville
      ? [
          {
            id: '-1',
            label: capitalize(ocrData.adresse.ville),
          },
        ]
      : []
  );
  const [triggerGetCitiesByZipCd, resultGetCitiesByZipCd] =
    useLazyGetCitiesByZipCdQuery();
  const [isCityInputEditMode, setIsCityInputEditMode] = useState(false);
  const [isCityInvalide, setIsCityInvalide] = useState(false);

  const updateCities = (zipCode: string) => {
    if (zipCodeRegexp.test(zipCode)) {
      triggerGetCitiesByZipCd(zipCode).then((data) => {
        const newList = data?.data?.map((ville) => {
          return { id: ville.code, label: ville.label };
        });
        if (newList) {
          setCityList(newList);
          methods.setValue('city', newList[0].label, { shouldValidate: true });
          methods.setValue('citySelect', newList[0].id, {
            shouldValidate: true,
          });
        }
      });
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <Common
            isIncompleteScan={getIncompleteReadBo()}
            isFormValid={methods.formState.isValid}
            isMaxReuploadAttempt={isMaxReuploadAttempt}
            onReupload={onReupload}
          >
            <WrapperSubTitle>
              <Subtitle>{messages.SUB_TITLE_CONTACT_INFO}</Subtitle>
            </WrapperSubTitle>
            <WrapperBody>
              <FormEditableField
                label={messages.ADDRESS}
                name="streetAddress"
                pattern={addressRegExp}
                defaultValue={
                  ocrData?.adresse?.adresse_complete
                    ? capitalize(ocrData.adresse.adresse_complete)
                    : undefined
                }
                maxLength={30}
              />
              <FormEditableField
                label={messages.ZIPCD}
                name="zipCd"
                pattern={zipCodeRegexp}
                defaultValue={
                  ocrData?.adresse?.cod_postal
                    ? ocrData.adresse.cod_postal.replace(/\D/g, '')
                    : undefined
                }
                onChange={(zipCode) => {
                  setIsCityInputEditMode(true);
                  updateCities(zipCode.toString());
                }}
                maxLength={5}
              />
              {cityList.length > 1 || isCityInvalide ? (
                <FormEditableField
                  label={messages.CITY}
                  name="citySelect"
                  defaultValue={
                    ocrData?.adresse?.ville
                      ? capitalize(ocrData.adresse.ville)
                      : undefined
                  }
                  isEditMode={true}
                  render={(
                    { onChange, value, ref, onBlur, name },
                    { invalid, isTouched }
                  ) => (
                    <Select
                      name={name}
                      options={cityList}
                      value={value}
                      isTouched={isTouched}
                      isValid={!invalid}
                      onBlur={onBlur}
                      inputRef={ref}
                      isDisabled={cityList.length === 0}
                      onChange={(value) => {
                        onChange(value);
                        const foundCity = cityList?.find(
                          (city) => city.id === value
                        );
                        methods.setValue('city', foundCity?.label, {
                          shouldValidate: true,
                        });
                      }}
                      hasSearch
                    />
                  )}
                />
              ) : (
                <FormEditableField
                  label={messages.CITY}
                  name="city"
                  pattern={cityRegExp}
                  isReadOnly
                  isEditMode={isCityInputEditMode || !ocrData?.adresse?.ville}
                  defaultValue={
                    ocrData?.adresse?.ville
                      ? capitalize(ocrData.adresse.ville)
                      : undefined
                  }
                  render={(
                    { onChange, value, ref, onBlur, name },
                    { invalid }
                  ) => (
                    <Input
                      name={name}
                      value={value}
                      isDisabled={cityList.length <= 1}
                      isValid={!invalid}
                      onBlur={onBlur}
                      inputRef={ref}
                    />
                  )}
                />
              )}
            </WrapperBody>
          </Common>
        </form>
      </FormProvider>
    </>
  );
};

export default FormOcrDom;
